<div class="admin-concept-lists">
  <app-top-header backRouterLink="/admin"
                  backRouterLinkLabel="TRANS__SECTION__ADMIN__TITLE"
                  [parent]="parent"
                  class="admin-header-container"></app-top-header>

  <app-header-and-description heading="TRANS__ADMIN_PAGE__CONCEPTS__TITLE"
                              description="TRANS__ADMIN_PAGE__CONCEPTS__INGRESS">
  <div class="admin-concept-lists__content admin-concept-lists__content-v2-override">
    <div class="admin-concept-lists__top-content">
      <div class="first-col-max-width">
        <app-admin-concept-query-concept-types
          (querySubmitter)="onQueryFilterChange($event)"
          class="admin-concept-lists__search-query"
        ></app-admin-concept-query-concept-types>
      </div>
      <div class="second-col-min-width
      admin-concept-lists__top-content__second-column">
        <app-admin-concept-object-filter-menu
          (objectFilterChange)="onObjectFilterChange($event)"
        ></app-admin-concept-object-filter-menu>
      </div>
    </div>
    <div class="admin-concept-lists__main-content admin-concept-lists__main-content-v2-override">
      <div class="first-col-max-width admin-concept-lists__concept-list-container">
        <app-admin-concept-concept-list-selector
          *ngIf="conceptTypeMainMenus"
          [conceptTypeMainMenus]="conceptTypeMainMenus"
          (conceptTypeMenuChange)="onConceptTypeMenuChange($event)">
        </app-admin-concept-concept-list-selector>
        <hr>
      </div>
      <div class="second-col-min-width admin-concept-lists__search-view-concept-container">
        <app-admin-list
          *ngIf="conceptsContainer && curConceptTypeMenu"
          [menuTitle]="curConceptTypeMenu.menuTitle"
          [conceptsContainer]="conceptsContainer"
        ></app-admin-list>
      </div>
    </div>

  </div>
  </app-header-and-description>
</div>

<div class="filter-menu">
  <div class="filter-menu__top-row">
    <app-filter-menu-saved-search-selector
      [fsi]="fsi"
      [searchContainer]="searchContainer"
      [trigger]="updateTrigger"
    />

    <button mat-icon-button (click)="closeDrawer.emit()" class="filter-menu__menu-toggle-button">
      <mat-icon>menu_open</mat-icon>
    </button>
  </div>

  <div class="filter-menu__scrollable">
    <app-filter-menu-category-selector
      (triggerLoading)="triggerLoadingState()"
      [categories]="categories"
      [searchContainer]="searchContainer"
      [trigger]="updateTrigger"
    />

    @if (searchContainer.queryContainer?.selectedQueryMenu?.query_type !== 'advanced') {
      <app-filter-menu-filter-list
        [fsi]="fsi"
        [loading]="loadingState"
        [searchContainer]="searchContainer"
        [subCategories]="subCategories"
      />
    }
    @else {
      <app-filter-menu-search-suggestion-list
        [searchContainer]="searchContainer"
      />
    }
  </div>

  @if (!useNewLayout) {
    <app-floating-feature-toggle-switch
      feature="experimental.useNewFilterMenu"
      [featureLabel]="'TRANS__FEATURE__USE_NEW_FILTER_MENU' | translate"
      bottom="2rem"
      left="2rem"
      (afterToggle)="reloadPage()"
    >
      <span>
        {{ 'TRANS__OBJECT_PAGE__GO_BACK_TO_OLD_DESIGN' | translate }}
      </span>
    </app-floating-feature-toggle-switch>
  }
</div>

<div>
  @if(data.selectorContainer) {
    <div>
      <app-search-view-selector
        [selectorContainer]="data.selectorContainer"
      />
    </div>
  }
  @else {
    <p>
      no selector
    </p>
  }
</div>

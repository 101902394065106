
<app-object-page-layout>
  <app-object-page-heading
    slot="heading"
    [title]="artifact?.artifact_name"
    [image]="thumbnailUrl()"
      [placeholderIcon]="placeholderThumbnailIcon()"
    [loading]="loadingArtifact"
      [hideInfoPopover]="isOverviewPage()"
  >
    <div slot="information-popover-content" class="info-popover" (click)="$event.stopPropagation()">

      @if (objectView) {
        <!-- need to include the wrapper again here... the popover is teleported to the outside. -->
        <app-object-overview-fields-v2
          [objectView]="objectView"
          [isEditing]="isEditing"
        >
              <span slot="title" class="overview-title">
                {{ artifact?.artifact_name }}
              </span>
        </app-object-overview-fields-v2>
      }
    </div>
  </app-object-page-heading>

  <app-object-page-operations-toolbar
      [disabled]="isEditing"
    slot="operations"
    [actions]="headerActions"
    (actionClick)="operationClick($event)"
  ></app-object-page-operations-toolbar>

  <app-object-page-search-navigation
    slot="search-navigation"
    [artifactId]="artifact?.artifact_id"
    [searchContext]="currentSearchContext"
    (backClick)="onBackClick()"
    (forwardClick)="onForwardClick()"
      (closeClick)="onCloseClick()">
    </app-object-page-search-navigation>

    <div slot="left-drawer" class="flex flex-1 flex-col justify-between h-full">
    @if (loadingNavigationItems) {
      <div class="skeleton-navitems-container">
        <div class="skeleton-navitem-row skeleton"></div>
        <div class="skeleton-navitem-row skeleton"></div>
        <div class="skeleton-navitem-row skeleton"></div>
        <div class="skeleton-navitem-row skeleton"></div>
        <div class="skeleton-navitem-row skeleton"></div>
        <div class="skeleton-navitem-row skeleton"></div>
      </div>
    } @else {
        <div class="side-navigation-wrapper">
      <app-object-page-navigation
        [items]="navigationItems"
        [initialSelectedItem]="initialActiveChild"
      >
      </app-object-page-navigation>

      @if (artifact) {
        <app-recto-verso-buttons [object]="artifact"></app-recto-verso-buttons>
      }

        </div>
      @if (contentInfo.artifact) {
        <div class="select-template-group-wrapper">
          <app-select-object-template-button
            [templateGroupId]="contentInfo.templateGroupId"
            [superobject_type_id]="contentInfo.artifact?.superobject_type_id"
            (changeTemplateGroupId)="onChangeTemplateGroup($event)"
          >
          </app-select-object-template-button>
        </div>
      }
    }

  </div>

  <router-outlet></router-outlet>

  <div slot="right-drawer" class="h-full">
    @if (loadingMedia) {
      <div class="skeleton-imagedrawer-container">
        <div class="image-container">
          <div class="skeleton"></div>
        </div>
        <div class="thumnails">
          <div class="skeleton"></div>
          <div class="skeleton"></div>
          <div class="skeleton"></div>
        </div>
      </div>

      } @else if (artifact?.$$mediaContainer?.mediaTypes?.length > 0) {
      <app-media-carousel
        [startMediaId]="artifact.$$mediaContainer.mediaTypes?.[0]?.mediaElements?.[0]?.mediaObject?.artifact_id"
        [mediaContainer]="artifact.$$mediaContainer"
        [sectionsContainer]="sectionsContainer"
        [curAnn]="curAnn"
        [isEditing]="isEditing"
        (openImageFullScreen)="openImageFullScreen($event)"
        (openModelFullScreen)="openModelFullscreen($event)"
        (objectRefresh)="refreshObject($event)"
      ></app-media-carousel>

      @if (imageFullScreen) {
        <app-image-full-screen
          class="image-full-screen-container"
          [mediaContainer]="artifact.$$mediaContainer"
          (closeImageFullScreen)="closeImageFullScreen()"
          [curAnn]="curAnn"
          [toggleAnnotations]="toggleAnnotations"></app-image-full-screen>
      }

      @if (modelFullscreen) {
        <app-model-full-screen
          class="image-full-screen-container"
          [mediaContainer]="artifact.$$mediaContainer"
          (closeModelFullScreen)="closeModelFullscreen()"
        ></app-model-full-screen>
      }
      } @else {
        <div class="p-4 missing-image-wrapper">
          <p class="mat-body-medium">{{ 'TRANS__OBJECT_PAGE_V2__NO_MEDIA_MESSAGE' | translate }}</p>
          <mat-icon>hide_image</mat-icon>
        </div>
    }
  </div>
</app-object-page-layout>

@if (newReportObject()) {
  <new-report-sidemenu
    [newReportObject]="newReportObject"
  ></new-report-sidemenu>
}

@if (selectorContainer()) {
  <div> - {{selectorContainer()}}</div>

  <app-search-view-selector
    [selectorContainer]="selectorContainer()"
  ></app-search-view-selector>
}

@if (!useNewLayout) {
  <app-floating-feature-toggle-switch
    bottom="3rem"
    left=".5rem"
    feature="experimental.useNewObjectPage"
    [featureLabel]="'TRANS__FEATURE__USE_NEW_OBJECT_PAGE' | translate"
    (afterToggle)="navigateToOldDesign()"
  >
    <span>
      {{ 'TRANS__OBJECT_PAGE__GO_BACK_TO_OLD_DESIGN' | translate }}
    </span>
  </app-floating-feature-toggle-switch>
}



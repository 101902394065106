<div class="search-paginator">
  <mat-paginator
    class="search-paginator__paginator density--5"
    (page)="paginationChange($event)"
    [length]="searchContainer.searchResult?.search_count || 0"
    [pageSize]="searchContainer.rows"
    [pageSizeOptions]="[50, 100, 200]"
    [showFirstLastButtons]="false"
  />
</div>



<div>
  <a routerLink="/service" class="back-link">
    <mat-icon class="material-symbols-outlined">arrow_back</mat-icon>
    Tilbake til tjenester
  </a>
</div>

<h1 class="heading">Flagg</h1>
<p class="sub-heading">Her kan du overstyre enkelte feature-flagg manuelt. Endringen gjelder kun på denne enheten.</p>

<form [formGroup]="form">
  <fieldset formGroupName="experimental">
    <legend>Eksperimentelle funksjoner</legend>
    <div class="flag">
      <div>
        <mat-checkbox formControlName="useNewObjectPage">
          Ny objektside
        </mat-checkbox>
      </div>
      <span class="description">
        Bruk ny layout og design for objektsiden.
      </span>
    </div>

    <div class="flag">
      <div>
        <mat-checkbox formControlName="useNewFilterMenu">
          Ny filtermeny
        </mat-checkbox>
      </div>
      <span class="description">
        Bruk ny layout og design for filtermenyen.
      </span>
    </div>

    <div class="flag">
      <div>
        <mat-checkbox formControlName="useNewMainMenu" (click)="toggleNewLayout()">
          {{ 'TRANS__FEATURE_FLAGS_WIDGET__NEW_MAIN_MENU_TITLE' | translate }}
        </mat-checkbox>
      </div>
      <span class="description">
        {{ 'TRANS__FEATURE_FLAGS_WIDGET__NEW_MAIN_MENU_DESCRIPTION' | translate }}
      </span>
    </div>
  </fieldset>

  <button mat-flat-button type="submit" (click)="saveFlags()">Lagre</button>
  <button mat-button type="button" (click)="resetFlags()">Tilbakestill alt</button>
</form>

<mat-list class="notifications">
  @for (notification of notifications(); track notification.id) {
    <mat-list-item>{{ notification.message }}</mat-list-item>
  }
</mat-list>



<div class="footer">
  @for (operation of operations; track $index) {
    <div class="footer__nav-button">
      @if(operation.router_link !== 'search') {
        <button
          mat-icon-button
          routerLinkActive="footer__nav-button--active"
          [queryParams]="getQueryParams()"
          [routerLink]="operation.router_link"
        >
          <mat-icon>
            {{operation.icon}}
          </mat-icon>
        </button>
      }
      @else {
        <button
          mat-icon-button
          (click)="goToSearch()"
          [ngClass]="{'footer__nav-button--active' : currentStateName === 'home.primus.search' }"
        >
          <mat-icon>
            {{operation.icon}}
          </mat-icon>
        </button>
      }

      <span class="footer__nav-label">
          {{operation.name | translate}}
        </span>
    </div>
  }
</div>

import {Component, Input} from '@angular/core';
import {JobStatusItem} from "../../../core/definitions/job-status-item";
import {MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle} from "@angular/material/expansion";
import {MatAnchor, MatButton} from "@angular/material/button";
import {TranslateModule} from "@ngx-translate/core";
import {RouterLink} from "@angular/router";
import {PrimusStateMapperService} from "../../../core/primus-state-mapper.service";
import {MatIcon} from "@angular/material/icon";
import {NgIf} from "@angular/common";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-job-status-item',
  standalone: true,
  imports: [
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatButton,
    TranslateModule,
    MatAnchor,
    RouterLink,
    MatExpansionPanelHeader,
    MatIcon,
    NgIf,
    MatProgressSpinner
  ],
  templateUrl: './job-status-item.component.html',
  styleUrl: './job-status-item.component.scss'
})
export class JobStatusItemComponent {
  @Input() message: JobStatusItem;

  constructor(
    public primusStateMapperService: PrimusStateMapperService
  ) {}
}

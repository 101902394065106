import {Component, effect, forwardRef, Inject, WritableSignal} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SelectorContainer} from "../../core/definitions/selector-container";
import {ObjectSearchModule} from "../../object-search/object-search.module";

export interface DrawerModalWrapperData {
  show: WritableSignal<boolean>;
  selectorContainer: SelectorContainer;
}

@Component({
  selector: 'app-drawer-modal-wrapper',
  standalone: true,
  templateUrl: './drawer-modal-wrapper.component.html',
  styleUrl: './drawer-modal-wrapper.component.scss',
  imports: [
    forwardRef(() => ObjectSearchModule),
  ]
})
export class DrawerModalWrapperComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DrawerModalWrapperData,
    public dialogRef: MatDialogRef<DrawerModalWrapperComponent>
  ) {
    effect(() => {
      if (!this.data.show()) {
        this.dialogRef.close();
      }
    });
  }
}

import {Injectable} from '@angular/core';
import {Facet, FacetItem, FilterGroupHierarchyNode, HierarchicFilterGroup} from '../core/definitions/search-objects';
import {CommonsService} from '../core/commons.service';
import {SearchContainer} from '../core/definitions/search-container';
import {LoggerService} from '../core/logger.service';
import {ProgressDialogComponent} from '../shared/progress-dialog/progress-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {SearchViewFilterService} from "./search-view-filter.service";

@Injectable({
  providedIn: 'root'
})
export class HierarchicFilterGroupService {

  constructor(private matDialog: MatDialog,
              private commons: CommonsService,
              private logger: LoggerService,
              private searchViewFilterService: SearchViewFilterService) {
  }

  async setHierarchicFilterGroup(searchContainer: SearchContainer) {
    searchContainer.filtersFacets.hierarchicFilterGroup = null;
    const orig = await this.searchViewFilterService.getHierarchicFilterGroupFromSearchView(
      searchContainer.currentPathView.search_view);
    let res: HierarchicFilterGroup;
    if (orig) {
      const progressModal = this.matDialog.open(ProgressDialogComponent, {
        disableClose: true,
        panelClass: 'progress-modal'
      });
      try {
        res = this.commons.copy(orig);
        this.generateHierarchy(searchContainer, res);
        this.generateHierarchyArrays(res);
        progressModal.close();
      } catch (e) {
        this.logger.error('Error generating filter hierarchy: ' + e);
        progressModal.close();
      }
      searchContainer.filtersFacets.hierarchicFilterGroup = res;
    }
  }

  private generateHierarchy(searchContainer: SearchContainer, hierarchicGroup: HierarchicFilterGroup) {
    hierarchicGroup.children = {};
    const searchRes = searchContainer.searchResult;
    const facet = this.getHierarchicFacet(hierarchicGroup.list_filter_field, searchRes.facets);
    if (!facet) {
      return;
    }
    const isPlaceHierarchy = hierarchicGroup.list_filter_field === 'm_path';
    for (const item of facet.items) {
      const itemName = this.getCleanItemName(item.name);
      if (!itemName) {
        continue;
      }
      let splitIds: string[];
      if (isPlaceHierarchy) {
        splitIds = item.id.split('/');
        splitIds.shift();
      } else {
        splitIds = itemName.split('» ');
      }
      let currentHierarchy = hierarchicGroup;

      for (const nodeId of splitIds) {
        const existing = currentHierarchy.children[nodeId];
        currentHierarchy.children[nodeId] = existing || new HierarchicFilterGroup();
        currentHierarchy = <HierarchicFilterGroup>currentHierarchy.children[nodeId];
      }
      currentHierarchy.facet = this.beautifyItem(item);
      currentHierarchy.facet.selected = false;
    }
    if (isPlaceHierarchy) {
      this.setPlaceCounts(hierarchicGroup);
    }
  }

  // Due to using the single value field m_path as facet for place hierarchy, the facet counts are incorrect
  // Must traverser hierarchy to get correct counts
  private setPlaceCounts(hierarchicGroup: HierarchicFilterGroup) {
    for (const childNode of Object.values(hierarchicGroup.children)) {
      if (childNode.children) {
        childNode.facet.count += this.getChildCount(childNode.children);
      }
    }
  }

  private getChildCount(children: {[name:string]: FilterGroupHierarchyNode}): number {
    let res = 0;
    for (const childId in children) {
      const facet = children[childId].facet;
      res += facet.count;
      const grandChildren = children[childId].children;
      if (grandChildren) {
        const recursiveCount = this.getChildCount(grandChildren);
        res += recursiveCount;
        facet.count += recursiveCount;
      }
    }
    return res;
  }

  private getHierarchicFacet(facetName: string, facets: Facet[]): Facet {
    let res = facets.find(facet => facet.f_name === facetName);
    if (!res) {
      this.logger.warn(`Facet not found: ${facetName}`);
    }
    return res;
  }

  // Remove extra item name information that prevents building of hierarchy from working
  private getCleanItemName(itemNameIn: string): string {
    let res = itemNameIn.replace('<del>', '');
    if (itemNameIn.endsWith(')')) {
      const lastParenthesisStart = itemNameIn.lastIndexOf(' (');
      if (lastParenthesisStart !== -1) {
        res = res.substring(0, lastParenthesisStart);
      }
    }
    for (const unclean of [': ']) {
      const unCleanIndex = res.indexOf(unclean);
      if (unCleanIndex !== -1) {
        res = res.substring(0, unCleanIndex);
        break;
      }
    }
    return res;
  }

  private beautifyItem(item: FacetItem): FacetItem {
    let itemName = item.name;
    let addDel = ''; // itemName.startsWith('<del>') ? '<del>' : '';
    const splitNames = itemName.split('» ');
    item.shortName = addDel + splitNames[splitNames.length - 1];
    return item;
  }

  // Generating and using arrays will speed up the GUI for drawing nodes
  private generateHierarchyArrays(filterGroupHierarchyNode: FilterGroupHierarchyNode) {
    filterGroupHierarchyNode.childrenArray = Object.values(filterGroupHierarchyNode.children).sort(this.sortNodes);
    for (const node of filterGroupHierarchyNode.childrenArray) {
      this.generateHierarchyArrays(node);
    }
  }

  private sortNodes(node1: FilterGroupHierarchyNode, node2: FilterGroupHierarchyNode) {
    if (!node1.facet.name || !node2.facet.name) {
      return 0;
    }
    const node1Name = node1.facet.name.toLocaleUpperCase();
    const node2Name = node2.facet.name.toLocaleUpperCase();
    if (node1Name < node2Name) {
      return -1
    } else if (node1Name > node2Name) {
      return 1
    } else {
      return 0;
    }
  }
}

import {Input} from '@angular/core';
import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {BehaviorSubject, from, Observable} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {AdminUsersService} from '../admin-users.service';
import User from '../User';
import {SuperObjectModel} from '../../../core/definitions/super-object-model';

export enum ListType {
  LIST = 0,
  AVATAR_LIST = 1
}

export enum UserAction {
  DEACTIVATE_USERS,
  ACTIVATE_USERS,
}

enum CreatedTime {
  ANY_TIME = '',
  ONE_DAY = '[NOW-1DAY/DAY TO NOW]',
  ONE_WEEK = '[NOW-7DAY/DAY TO NOW]',
  ONE_MONTH = '[NOW-30DAY/DAY TO NOW]',
  ONE_YEAR = '[NOW-1YEAR/DAY TO NOW]'
}

interface ListOption<T> {
  icon?: string;
  value: T;
  label: string;
  adminOnly?: boolean;
}

interface KeyValue {
  key: string;
  value: string | number;
}

@Component({
  selector: 'app-admin-users-filter',
  templateUrl: './admin-users-filter.component.html',
  styleUrls: ['./admin-users-filter.component.scss']
})
export class AdminUsersFilterComponent implements OnDestroy, OnInit {

  @Input() selectedUsers: SuperObjectModel[];
  @Output() public readonly filterChanged: EventEmitter<Array<any>>;
  @Output() public readonly actionEvent: EventEmitter<UserAction>;
  @Output() public readonly listTypeChanged: Observable<ListType>;

  readonly listTypeChanged$: BehaviorSubject<ListType>;

  readonly filterControl: UntypedFormControl;
  readonly listTypeOptions: Array<ListOption<ListType>> = [
    {
      icon: 'reorder',
      value: ListType.LIST,
      label: 'TRANS__ADMIN__USERS__LIST_TYPE__LIST'
    }, {
      icon: 'view_list',
      value: ListType.AVATAR_LIST,
      label: 'TRANS__ADMIN__USERS__LIST_TYPE__AVATAR_LIST'
    }
  ];

  readonly actionOptionsList: Array<ListOption<UserAction>> = [
    {
      icon: 'restore',
      value: UserAction.ACTIVATE_USERS,
      label: 'TRANS__ADMIN__USERS__ACTION__ACTIVATE_USERS',
      adminOnly: true,
    }, {
      icon: 'delete_outline',
      value: UserAction.DEACTIVATE_USERS,
      label: 'TRANS__ADMIN__USERS__ACTION__DEACTIVATE_USERS',
      adminOnly: true,
    },
  ];

  readonly createdTimeFilterOptions: Array<ListOption<CreatedTime>> = [
    {
      value: CreatedTime.ANY_TIME,
      label: 'TRANS__ADMIN__USERS__FILTER_CREATED_AT__ANY_TIME'
    }, {
      value: CreatedTime.ONE_WEEK,
      label: 'TRANS__ADMIN__USERS__FILTER_CREATED_AT__LAST_WEEK'
    }, {
      value: CreatedTime.ONE_MONTH,
      label: 'TRANS__ADMIN__USERS__FILTER_CREATED_AT__LAST_MONTH'
    }, {
      value: CreatedTime.ONE_YEAR,
      label: 'TRANS__ADMIN__USERS__FILTER_CREATED_AT__LAST_YEAR'
    },
  ];

  displayActive: boolean;
  isUsersSelected: boolean;
  availableAccessRights: Array<KeyValue>;
  selectedAccessRightIds: Array<string>;
  availableCreateByUser: Array<KeyValue>;
  selectedCreatedByUser: Array<string>;
  selectedCreatedTime: CreatedTime;

  private searchString: string;
  private usersSelectedSubscriber: BehaviorSubject<any>;

  constructor(private readonly usersService: AdminUsersService) {
    this.listTypeChanged$ = new BehaviorSubject<ListType>(ListType.AVATAR_LIST);
    this.listTypeChanged = this.listTypeChanged$.asObservable();

    // Handle search by string
    this.filterControl = new UntypedFormControl(null);
    this.filterControl.valueChanges.pipe(debounceTime(500)).subscribe(v => {
      this.searchString = v;
      this.filterChanged.emit(this.getCurrentFilterValues());
    });

    this.actionEvent = new EventEmitter<UserAction>();
    this.filterChanged = new EventEmitter<Array<Partial<User>>>();
  }

  public ngOnInit() {
    this.usersSelectedSubscriber = this.usersService.usersSelected;
    this.usersSelectedSubscriber.subscribe(val => {
      this.isUsersSelected = val;
    });

    this.loadRightsLevels().subscribe(levels => {
      this.availableAccessRights = levels;
    });
    this.setupFilterData();
    this.filterChanged.emit(this.getCurrentFilterValues());

    setTimeout(() => {
      this.filterChanged.emit(this.getCurrentFilterValues());
    }, 500);
  }

  public ngOnDestroy(): void {
    this.listTypeChanged$.complete();
  }

  toggleSelectItem(list: Array<string>, item: string, event: MouseEvent): void {
    // Avoids closing the filter-menu when toggling checkboxes:
    event.stopPropagation();
    if (!list.includes(item)) {
      list.push(item);
    } else {
      const idx = list.indexOf(item);
      if (idx >= 0) {
        list.splice(idx, 1);
      }
    }
    this.filterChanged.emit(this.getCurrentFilterValues());
  }

  get selectedListType(): Observable<ListOption<ListType>> {
    return this.listTypeChanged$.pipe(
      map(type =>
        this.listTypeOptions.find(opt => opt.value === type)
      )
    );
  }

  get isAdmin(): boolean {
    return this.usersService.isAdmin;
  }

  set active(active: boolean) {
    this.displayActive = active;
    this.filterChanged.emit(this.getCurrentFilterValues());
  }

  set createdTimeFilterValue(value: CreatedTime) {
    this.selectedCreatedTime = value;
    this.filterChanged.emit(this.getCurrentFilterValues());
  }

  private getCurrentFilterValues(): any {
    const values = {
      deactivated: !this.displayActive
    };

    if (this.selectedCreatedTime !== CreatedTime.ANY_TIME) {
      values['created_at'] = this.selectedCreatedTime;
    }

    if (this.selectedAccessRightIds.length > 0) {
      const params = [];
      for (let i = 0, max = this.selectedAccessRightIds.length; i < max; i++) {
        const id = this.selectedAccessRightIds[i];
        const match = this.availableAccessRights.find(r => r.key === id);
        if (match) {
          params.push(match.key);
        }
      }
      values['rights_level_id'] = params;
    }

    if (this.searchString && this.searchString !== '') {
      values['full_name'] = `*${this.searchString}*`;
    }

    return values;
  }

  private setupFilterData(): void {
    this.displayActive = true;
    this.selectedCreatedTime = CreatedTime.ANY_TIME;
    this.selectedAccessRightIds = [];
    this.selectedCreatedByUser = [];
  }

  private loadRightsLevels(): Observable<Array<KeyValue>> {
    return from(this.usersService.loadRightsLevels())
      .pipe(
        map((res: any[]) => res?.map(level => {
          return {
            key: level.artifact_id,
            value: level.artifact_name
          };
        })
        )
      );
  }
}

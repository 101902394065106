import {Injectable} from '@angular/core';
import {CmsApiService} from "../core/cms-api.service";
import {
  CheckFilterGroup,
  HierarchicFilterGroup, RangeGroup,
  SearchView,
  SearchViewFilters
} from "../core/definitions/search-objects";
import {SearchContainer} from "../core/definitions/search-container";

@Injectable({
  providedIn: 'root'
})
export class SearchViewFilterService {

  constructor(
    private cms: CmsApiService) {
  }

  private searchViewFilters: SearchViewFilters;

  async getSearchViewFilters(): Promise<SearchViewFilters> {
    if (!this.searchViewFilters) {
      this.searchViewFilters = await this.cms.getSearchViewFilters();
    }
    return this.searchViewFilters;
  }

  async setCheckFilterGroupsFromSearchView(searchContainer: SearchContainer) {
    console.log('Getting/setting search filter groups on search container')
    searchContainer.filtersFacets.filterGroups = await this.getCheckFilterGroupsFromSearchView(
      searchContainer.currentPathView.search_view);
  }

  async getCheckFilterGroupsFromSearchView(searchView: SearchView): Promise<CheckFilterGroup[]> {
    let res: CheckFilterGroup[] = [];
    if (searchView?.check_filter_groups) {
      const checkFilterGroupNames = searchView.check_filter_groups;
      if (typeof checkFilterGroupNames[0] === "string") {
        res = [];
        const filters = await this.getSearchViewFilters();
        for (const groupName of checkFilterGroupNames) {
          res.push(filters.check_filter_groups[groupName]);
        }
      } else {
        // This is the old definition, in which check_filter_groups contained filter group objects instead of names
        const groups = <unknown>checkFilterGroupNames;
        res = JSON.parse(JSON.stringify(<CheckFilterGroup[]>groups));
      }
    }
    return res;
  }

  async getHierarchicFilterGroupFromSearchView(searchView: SearchView): Promise<HierarchicFilterGroup> {
    const groupName = searchView.hierarchic_filter_group;
    if (!groupName) {
      return null;
    }
    if (typeof groupName === 'string') {
      const searchViewFilters = await this.getSearchViewFilters();
      return searchViewFilters.hierarchic_filter_groups[groupName];
    } else {
      // Old version
      const group = <unknown>groupName;
      return <HierarchicFilterGroup>group;
    }
  }

  async getFacetRangeGroupsFromSearchView(searchView: SearchView): Promise<RangeGroup[]> {
    const filters = await this.getSearchViewFilters();
    let res: RangeGroup[] = [];
    const facetRangeGroupNames = searchView.facet_range_groups;
    if (facetRangeGroupNames?.length) {
      if (typeof facetRangeGroupNames[0] === 'string') {
        for (const facetGroupName of facetRangeGroupNames || []) {
          res.push(filters.facet_range_groups[facetGroupName]);
        }
      } else {
        // Old version
        const groups = <unknown>facetRangeGroupNames;
        return <RangeGroup[]>groups;
      }
    }
    return res;
  }

}


  <div class="reports-header">
    <h1>{{'TRANS__REPORTS_V2_HEADER' | translate}}</h1>
  </div>

  <div class="reports-menu">
    <ul class="reports-menu__tabs">
      @for (menuItem of menuItems; track menuItem.id) {
        <li [class.closed]="!menuItem.isOpen" [class.open]="menuItem.isOpen" [routerLink]="menuItem.openButtonLink">
          <!--
          @if (menuItem.icon) {
              <div class="material-icons">{{menuItem.icon}}</div>
            }
           -->
            <span>{{menuItem.name | translate}}</span>
        </li>
      }

      </ul>
    </div>

  <div class="reports-content">
    <router-outlet></router-outlet>
  </div>


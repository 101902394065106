import {Injectable} from '@angular/core';
import {SearchContainer} from '../core/definitions/search-container';

@Injectable()
export class CurrentSearchContext {
  constructor() {}

  // cache for optimistic ui
  private currentResultSize = 0;
  private currentSearchResults = [];
  private currentResultIndex = -1;

  private _searchContainer: SearchContainer;
  set searchContainer(sc: SearchContainer) {
    this._searchContainer = sc;
  }

  get searchTerm() {
    return this._searchContainer?.queryContainer?.query;
  }

  get resultSize() {
    const size = this._searchContainer?.searchResult?.search_count
    if (size != null) {
      this.currentResultSize = size;
    }
    return size ?? this.currentResultSize;
  }

  findResultIndex(artifactId: string) {
    const idx = this._searchContainer?.searchResult?.artifacts?.findIndex(artifact => artifact.artifact_id === artifactId);
    if (idx && idx >= 0) {
      this.currentResultIndex = idx;
    }
    if (idx < 0) {
      return this.currentResultIndex;
    }
    return idx ?? this.currentResultIndex;
  }

  findResult(artifactId: string) {
    return this._searchContainer?.searchResult?.artifacts?.find(artifact => artifact.artifact_id === artifactId);
  }

  get searchResults() {
    const results = this._searchContainer?.searchResult?.artifacts;
    if (results != null) {
      this.currentSearchResults = results;
    }
    return results ?? this.currentSearchResults;
  }
}

import {Component, OnInit} from '@angular/core';
import {CmsApiService} from '../../core/cms-api.service';
import {CommonsService} from '../../core/commons.service';
import {EventHarvesterService} from '../../core/event-harvester.service';
import {SimpleClientCache} from '../../core/simple-client-cache';
import {LoginService} from '../../core/login.service';
import {PrimusRouterService} from '../../core/primus-router.service';
import {FeatureFlagsService} from "../../core/feature-flags.service";
import {PrimusBackendInstanceService, PrimusInstanceDetails} from "../../core/primus-backend-instance.service";

export class ChangeValue {
  description: string;
  edition: any[];
}

export class ChangeItem {
  textVersion: string;
  version: string;
  value: ChangeValue[];
}

export class UserManual {
  manuals: UserManualItem[];
}

export class UserManualItem {
  href: string;
  title: string;
}

@Component({
  selector: 'app-about-primus',
  templateUrl: './about-primus.component.html',
  styleUrls: ['./about-primus.component.scss']
})
export class AboutPrimusComponent implements OnInit {

  changeList: ChangeItem[] = [];
  museum: PrimusInstanceDetails;
  parent;
  currentTab;
  currentChangeIndex = 0;
  edition = {
    title: ''
  };
  version;
  userManual: UserManual;

  private cacheKey = 'about';
  private cacheExpires = 3600 * 2;
  private simpleClientCache: SimpleClientCache = null;

  constructor(private loginService: LoginService,
              private primusRouter: PrimusRouterService,
              private cms: CmsApiService,
              private commons: CommonsService,
              private eventHarvester: EventHarvesterService,
              private readonly featureFlagsService: FeatureFlagsService) {
  }

  ngOnInit() {
    this.simpleClientCache = new SimpleClientCache(this.cacheKey, this.cacheExpires);
    this.setEditionAndVersion((edition, version) => {
      this.edition = edition;
      this.version = version;
    });

    this.userManual = {
      manuals: [{
        href: this.cms.getApiUrl('translation/manual/', true),
        title: 'TRANS__ABOUT_PRIMUS__USER_MANUAL'
      }],
    }
    this.parent = {
      adminType: 'about',
      hasSubMenus: false,
      currentState: this.primusRouter.currentState()
    };

    this.museum = PrimusBackendInstanceService.getInstanceDetails();

    this.getWhatIsNew();
  }

  setEmptyValue() {
    this.changeList.forEach((val) => {
      for (const key2 in val.value) {
        if (val.value.hasOwnProperty(key2)) {
          const value = val.value[key2];
          if ((value.edition.indexOf('small') === -1) && !value.edition.find(f => f === 'smallEmpty')) {
            value.edition.push('smallEmpty');
          }
          if ((value.edition.indexOf('medium') === -1) && !value.edition.find(f => f === 'mediumEmpty')) {
            value.edition.push('mediumEmpty');
          }
          if ((value.edition.indexOf('large') === -1) && !value.edition.find(f => f === 'largeEmpty')) {
            value.edition.push('largeEmpty');
          }
        }
      }
    });
  }

  tabButton(event) {
    this.currentChangeIndex = event.index;
  }

  logDownloadManual(name) {
    this.eventHarvester.fileDownload('manual', name);
  }

  private setEditionAndVersion(fn) {
    this.loginService.currentUser.subscribe(user => {
      if (user) {
        const edition = this.commons.setEditionTitle(user);
        const version = user['version'];
        fn(edition, version);
      }
    });
  }

  private parseData(data: {[name: string]: ChangeValue[]}) {
    let versions, highestVersion;

    versions = Object.keys(data).map((key) => {
      return key.replace(/[.]/g, '');
    });

    if (versions.indexOf('Unspecified') !== -1) {
      const index = versions.indexOf('Unspecified');
      versions.splice(index, 1);
    }
    this.sortArray(versions, true);

    if (versions && versions.length && versions.length > 0) {
      highestVersion = versions.reduce((a, b) => {
        return Math.max(parseFloat(a), parseFloat(b));
      });
    } else {
      highestVersion = null;
    }

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        if (key !== 'Unspecified') {
          const currentKey = parseFloat(key.replace(/[.]/g, ''));

          if (currentKey === highestVersion) {
            this.currentTab = key;
            this.currentChangeIndex = this.changeList.length;
          }
          const versionText =  key.replace(/[.]/g, '');

          this.changeList.push({
            version: key,
            textVersion: versionText,
            value: value.map(val => {
              return {
                edition: val.edition,
                description: this.commons.applyWordBreakOpportunity(val.description)
              };
            })
          });

        }
      }
    }
    if (this.changeList) {
      this.setEmptyValue();
    }
    this.sortArray(this.changeList, true, 'textVersion');
  }

  sortArray(array, reverse?, listName?) {
    array.sort(function(a, b) {
      return listName ? a[listName] - b[listName] : a - b;
    });
    if (reverse) {
      array.reverse();
    }
    return array;
  }

  private getWhatIsNew() {
    let cached = false;
    const cachedData = this.simpleClientCache.get();

    if (cachedData && Object.keys(cachedData).length > 0) {
      this.parseData(cachedData);
      cached = true;
    }

    this.cms.getWhatIsNew().then((data: {[name: string]: ChangeValue[]}) => {
        if (data && Object.keys(data).length === 0) {
          console.warn('Unable to obtain "What is new" information.');
          return;
        }
        this.simpleClientCache.put(data);
        if (!cached) {
          this.parseData(data);
          cached = true;
        }
       },
      (failed) => {
        console.error('This failed! ' + failed.message);
      }
    );

  }

  readonly useNewLayout = this.featureFlagsService.getFeatureFlags()?.experimental?.useNewMainMenu;
}

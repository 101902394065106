import {Component, Input, OnInit} from "@angular/core";
import {CmsApiService} from "../../core/cms-api.service";
import { SearchContainer } from "src/app/core/definitions/search-container";
import {ModelStore} from "../../core/ModelStore/ModelStore";
import {ReportUserGenerated} from "../../core/ModelStore/models/ReportUserGenerated";
import {Router} from "@angular/router";
import {ReportViewType} from "../../core/ModelStore/models/ReportViewType";
import {CurrentObjectContext} from "../../object-page-v2/current-object.context";

@Component({
  selector: 'new-report-sidemenu',
  templateUrl: './new-report-sidemenu.html',
  styleUrls: ['./new-report-sidemenu.scss']
})
export class NewReportSidemenu implements OnInit {
  @Input() searchContainer!: SearchContainer;
  @Input() newReportObject: any;
  newReport: ReportUserGenerated;
  reportViewTypes: ReportViewType[];
  newReportNameError: string = null;
  newReportShortDescriptionError: string = null;
  newReportViewTypeError: string = null;

  constructor(private readonly cms: CmsApiService,
              private modelStore: ModelStore,
              private router: Router) {
  }

  async validateForm() {
    this.newReport.name && this.newReport.name.length > 0 ? this.newReportNameError = null : this.newReportNameError = 'TRANS__REPORTS_V2_CREATE_REPORT_NAME_ERROR';
    this.newReport.reportViewTypeId ? this.newReportViewTypeError = null : this.newReportViewTypeError = 'TRANS__REPORTS_V2_CREATE_REPORT_VIEW_ERROR';

    console.log('errors? ', this.newReportNameError, this.newReportShortDescriptionError, this.newReportViewTypeError)
    if (this.newReportNameError || this.newReportShortDescriptionError || this.newReportViewTypeError) {
      return false;
    }

    await this.createReportAndForward();
  }

  async createReportAndForward() {
    this.newReport.artifactList = [];

    if (this.newReportObject && this.newReportObject()) {
      console.log(this.newReportObject());
      this.newReport.artifactList.push(this.newReportObject());
    } else if (this.searchContainer) {
      this.searchContainer.selections.selectedItems.forEach(item => {
        this.newReport.artifactList.push({ artifact_id: item.artifact_id, superobject_type_id: item.superobject_type_id, object_type: item.object_type });
      });
    }

    this.newReport.reportType = 'custom_user_report';

    let response = this.modelStore.createModel({modelSingularKey: 'report_user_generated',  record: this.newReport});
    await response.loading;
    await this.router.navigate(['/reports/generated-reports', response.id]);

  }

  async ngOnInit(): Promise<void> {
    this.newReport = new ReportUserGenerated();
    this.reportViewTypes = this.modelStore.findAllModels('report_view_type');
    // @ts-ignore
    await this.reportViewTypes.loading;
  }

  reportViewTypeChanged() {
    console.log('reportViewTypeChanged', this.newReport.reportViewTypeId);
  }

  cancelNewReport() {
    //@if(searchContainer.operationContainer?.currentOperation?.$$currentStep?.operation_step_type == 'create_new_report')
    if (this.searchContainer) {
      this.searchContainer.operationContainer.currentOperation.$$currentStep.operation_step_type = null;
    }
  }
}

@if (!newLayout && searchContainer && !route.children.length) {
  <section id="searchResult-main-content"
           class="search-page-component">

    <app-search-view-full
      [searchContainer]="searchContainer"
      [searchViewType]="searchContainer.currentPathView.search_view.search_view_type"
      class="search-page-component__search-view"
    ></app-search-view-full>

  </section>
}

@if (newLayout && searchContainer && !route.children.length) {
    <app-search-view-full-v2
      [searchContainer]="searchContainer"
      [searchViewType]="searchContainer.currentPathView.search_view.search_view_type"
    />
}

<ng-container *ngIf="route.children.length">
  <router-outlet></router-outlet>
</ng-container>

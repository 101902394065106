import {Component, Input, OnChanges, signal, WritableSignal} from '@angular/core';
import {OperationDef} from '../../core/definitions/operation-def';
import {OperationStep} from '../../core/definitions/operation-step';
import {Selector} from '../../core/definitions/reference';
import {animate, style, transition, trigger} from '@angular/animations';
import {OperationService} from '../operation.service';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {OperationContainer} from '../../core/definitions/operation-container';
import {FeatureFlagsService} from "../../core/feature-flags.service";
import {MatDialog} from "@angular/material/dialog";
import {DrawerModalWrapperComponent} from "../../object-page-v2/drawer-modal-wrapper/drawer-modal-wrapper.component";
import {SearchSelectorService} from "../../core/search-selector.service";
import {SelectorContainer} from "../../core/definitions/selector-container";

@Component({
  selector: 'app-operation-view-section-container',
  templateUrl: './operation-view-section-container.component.html',
  styleUrls: ['./operation-view-section-container.component.scss'],
  animations: [
    trigger('slideContent', [
      transition(':enter', [
        style({overflow: 'hidden',  height: '0px'}),
        animate('1000ms linear', style({height: '*'}))
      ])
    ])
  ]
})
export class OperationViewSectionContainerComponent implements OnChanges {
  @Input() operationContainer: OperationContainer;
  @Input() operationStepIndex: number;
  @Input() isDialog: boolean;

  showSecondaryModal: WritableSignal<boolean> = signal(false);

  operation: OperationDef;
  operationStep: OperationStep;
  sectionsContainers: SectionsContainer[];

  constructor(
    private readonly featureFlagsService: FeatureFlagsService,
    private readonly modalService: MatDialog,
    private operationService: OperationService,
    private readonly searchSelectorService: SearchSelectorService,
  ) { }

  ngOnChanges(): void {
    this.setOperationStep().then();
  }

  async setOperationStep() {
    this.operation = this.operationContainer.currentOperation;
    await this.operationService.setOperationStep(this.operationContainer);
    this.operationStep = this.operation.$$currentStep;
    this.sectionsContainers = this.operationService.getCurrentOperationSectionContainers(this.operationContainer);
    if (this.isDialog && this.sectionsContainers) {
      this.sectionsContainers[0].isDialog = true;
      this.sectionsContainers[0].hideSectionTitle = !this.operationStep.is_copy_step;
    }
  }

  enableSelector(selector: Selector) {
    if (this.featureFlagsService.getFeatureFlags()?.experimental?.useNewObjectPage) {
      this.showSecondaryModal.set(true);
      if (!this.operationContainer.searchContainer) {
        this.searchSelectorService.createSearchContainerForModalView(selector, <SelectorContainer>this.operationContainer).then((sc) => {
          this.searchSelectorService.setScSelectionCallback(selector, sc, {
            searchContainerCreated: () => {
            },
            selectorCallback: (selectedObj: any) => {
              this.showSecondaryModal.set(false);
              this.operationContainer.selectorEnabled = false;
              this.operationContainer.selectorCallback(selectedObj, selector);
            }
          })
          this.operationContainer.searchContainer = sc;
          this.operationContainer.selectorEnabled = true;

          this.modalService.open(DrawerModalWrapperComponent, {
            data: {
              show: this.showSecondaryModal,
              selectorContainer: this.operationContainer
            }
          });
        });
      }
      else {
        this.modalService.open(DrawerModalWrapperComponent, {
          data: {
            show: this.showSecondaryModal,
            selectorContainer: this.operationContainer
          }
        });
      }

    }
    else {
      if (this.isDialog) {
        this.operationContainer.inModal = true;
      }
      this.operationContainer.enableSelector(selector);
    }
  }
}

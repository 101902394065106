import { Component } from '@angular/core';
import {MatButton} from "@angular/material/button";
import {MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle} from "@angular/material/dialog";
import {MatLabel} from "@angular/material/form-field";
import {TranslateModule} from "@ngx-translate/core";
import {
  AdvancedSearchQueryBuilderInformationDialogComponent
} from "../advanced-search-query-builder-information-dialog/advanced-search-query-builder-information-dialog.component";

@Component({
  selector: 'app-advanced-search-query-builder-sql-dialog',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatLabel,
    TranslateModule
  ],
  templateUrl: './advanced-search-query-builder-sql-dialog.component.html',
  styleUrl: './advanced-search-query-builder-sql-dialog.component.scss'
})
export class AdvancedSearchQueryBuilderSqlDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AdvancedSearchQueryBuilderInformationDialogComponent>
  ) {}

  closeDialog(confirmed: boolean) {
    this.dialogRef.close(confirmed);
  }
}

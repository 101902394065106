@if (searchContainer.searchResult?.search_count === 0) {
  <div class="search-result__no-results">
    No result...
  </div>
}

@if (searchContainer.searchResult?.search_count > 0) {
  <div
    class="search-result__container"
    [ngClass]="{
      'search-result__container--thumbnails' : (searchContainer.searchResultViewName === viewNames.THUMBNAIL),
      'search-result__container--gallery' : (searchContainer.searchResultViewName === viewNames.GALLERY),
      'search-result__container--list-thumbnails' : (searchContainer.searchResultViewName === viewNames.LIST_THUMBNAIL),
      'search-result__container--list' : (searchContainer.searchResultViewName === viewNames.LIST)
    }"
  >
    @for (artifact of searchContainer.searchResult.artifacts; track $index) {
      @switch (searchContainer.searchResultViewName) {
        @case (viewNames.THUMBNAIL) {
          <app-search-result-view-thumbnail-v2
            [item]="artifact"
            [searchContainer]="searchContainer"
          />
        }

        @case (viewNames.GALLERY) {
          <app-search-result-view-gallery-v2
            [item]="artifact"
            [searchContainer]="searchContainer"
          />
        }

        @case (viewNames.LIST_THUMBNAIL) {
          <app-search-result-view-thumbnail-list-v2
            [item]="artifact"
            [searchContainer]="searchContainer"
          />
        }
      }
    }

    @if(searchContainer.searchResultViewName === viewNames.LIST) {
      <app-search-result-view-list-v2
        [searchContainer]="searchContainer"
      />
    }
  </div>
}


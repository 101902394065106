import {Component, Input, OnChanges} from '@angular/core';
import {SearchContainer} from "../../../../core/definitions/search-container";
import {FilterGroupHierarchyNode, HierarchicFilterGroup} from "../../../../core/definitions/search-objects";
import {
  MatTree,
  MatTreeFlatDataSource,
  MatTreeFlattener,
  MatTreeNode,
  MatTreeNodeDef,
  MatTreeNodePadding, MatTreeNodeToggle
} from "@angular/material/tree";
import {MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {FlatTreeControl} from "@angular/cdk/tree";
import {NgClass} from "@angular/common";
import {SearchExecutorService} from "../../../search-executor.service";

interface FilterHierarchyNode {
  expandable: boolean,
  filter: FilterGroupHierarchyNode,
  name: string,
  level: number
}

@Component({
  selector: 'app-filter-hierarchy',
  standalone: true,
  imports: [
    MatTree,
    MatTreeNode,
    MatTreeNodeDef,
    MatTreeNodePadding,
    MatIconButton,
    MatIcon,
    MatTreeNodeToggle,
    NgClass
  ],
  templateUrl: './filter-hierarchy.component.html',
  styleUrl: './filter-hierarchy.component.scss'
})
export class FilterHierarchyComponent implements OnChanges{
  @Input() searchContainer: SearchContainer;
  @Input() hierarchicFilterGroup: HierarchicFilterGroup;

  treeControl = new FlatTreeControl<FilterHierarchyNode>(
    node => node.level,
    node => node.expandable
  )

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.childrenArray
  )

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(
    private searchExecutorService: SearchExecutorService
  ) {}

  ngOnChanges() {
    if (this.hierarchicFilterGroup?.childrenArray) {
      this.dataSource.data = this.hierarchicFilterGroup.childrenArray;
    }
  }

  hasChild(_: number, node: any) {
    return node.expandable
  }

  checkNode(node: FilterHierarchyNode, event: MouseEvent) {
    event.stopPropagation();
    const lastSelectedNode = this.hierarchicFilterGroup.lastSelectedNode;

    for (const filter of this.hierarchicFilterGroup.childrenArray) {
      if (filter.facet.id === node.filter.facet.id) {
        if (!lastSelectedNode || lastSelectedNode.facet.id !== node.filter.facet.id) {
          if (lastSelectedNode) {
            lastSelectedNode.open = false;
            lastSelectedNode.facet.selected = false;
          }
          this.hierarchicFilterGroup.lastSelectedNode = node.filter;
          filter.facet.selected = true;
          filter.open = true;
        } else {
          lastSelectedNode.facet.selected = false;
          this.hierarchicFilterGroup.lastSelectedNode = null;
        }
      }

      if (filter.childrenArray && filter.childrenArray.length > 0) {
        for (const child of filter.childrenArray) {
          this.checkNodeChildren(child, node, true);
        }
      }
    }

    this.searchExecutorService.runSearch(this.searchContainer).then();
  }

  checkNodeChildren(node: FilterGroupHierarchyNode, checkedNode: FilterHierarchyNode, checked: boolean) {
    const lastSelectedNode = this.hierarchicFilterGroup.lastSelectedNode;

    if (node.facet.id === checkedNode.filter.facet.id) {
      if (!lastSelectedNode || lastSelectedNode.facet.id !== node.facet.id) {
        if (lastSelectedNode) {
          lastSelectedNode.open = false;
          lastSelectedNode.facet.selected = false;
        }
        this.hierarchicFilterGroup.lastSelectedNode = node;
        node.facet.selected = checked;
        node.open = checked;
      } else {
        lastSelectedNode.facet.selected = false;
        this.hierarchicFilterGroup.lastSelectedNode = null;
      }
    }

    if (node.childrenArray && node.childrenArray.length > 0) {
      for (const child of node.childrenArray) {
         this.checkNodeChildren(child, checkedNode, checked);
      }
    }
  }

  private transformer (node: FilterGroupHierarchyNode, level: number) {
    return {
      expanded: node.open,
      expandable: !!node.childrenArray && node.childrenArray.length > 0,
      filter: node,
      name: node.facet.shortName,
      level: level
    }
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {SearchContainer} from "../../../core/definitions/search-container";
import {SearchExecutorService} from "../../search-executor.service";

@Component({
  selector: 'app-search-view-pagination',
  standalone: true,
  imports: [
    MatPaginator
  ],
  templateUrl: './search-view-pagination.component.html',
  styleUrl: './search-view-pagination.component.scss'
})
export class SearchViewPaginationComponent implements OnInit {
  @Input() searchContainer: SearchContainer;



  constructor(
    private readonly searchExecutorService: SearchExecutorService
  ) {}

  ngOnInit() {
    if(this.searchContainer) {
      this.searchContainer.virtualScrollEnabled = false;
      this.searchContainer.searchPage = 1;
      this.searchContainer.rows = DEFAULT_SEARCH_ROWS;
      this.searchExecutorService.runSearch(this.searchContainer, true).then();
    }
  }

  paginationChange(event: PageEvent) {
    //const sc = this.searchContainer
    if (event.pageIndex + 1 !== this.searchContainer.searchPage) {
      this.searchContainer.searchPage = event.pageIndex + 1;
    }

    if (event.pageSize !== this.searchContainer.rows) {
      this.searchContainer.searchPage = 1;
      this.searchContainer.rows = event.pageSize;
    }

    this.searchExecutorService.runSearch(this.searchContainer, true).then();
  }
}

const DEFAULT_SEARCH_ROWS = 50;

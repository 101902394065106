import {Component, Input, OnInit} from '@angular/core';
import {LoginService} from "../../core/login.service";
import {UserData} from "../../core/definitions/user-data";
import {OperationService} from "../../operations/operation.service";
import {OperationContainer} from "../../core/definitions/operation-container";
import {OperationTarget} from "../../core/definitions/operation-target.enum";
import {MenuEntry} from "../main-menu-v2.component";
import {PrimusStateMapperService} from "../../core/primus-state-mapper.service";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {NgClass} from "@angular/common";
import {PrimusRouteService} from "../../core/primus-route.service";
import {PrimusRouterService} from "../../core/primus-router.service";

@Component({
  selector: 'app-main-menu-v2-footer',
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
    RouterLinkActive,
    TranslateModule,
    RouterLink,
    NgClass
  ],
  templateUrl: './main-menu-v2-footer.component.html',
  styleUrl: './main-menu-v2-footer.component.scss'
})
export class MainMenuV2FooterComponent implements OnInit {
  @Input() currentStateName: string;

  operationContainer: OperationContainer;
  operations: MenuEntry[] = [];

  constructor(
    private loginService: LoginService,
    private operationService: OperationService,
    private primusRouteService: PrimusRouteService,
    private primusRouterService: PrimusRouterService,
    private primusStateMapperService: PrimusStateMapperService
  ) {}

  ngOnInit() {
    this.loginService.currentUser.subscribe(user => {
      this.initialize(user).then();
    })
  }

  getQueryParams() {
    // Deep copy params
    const params = JSON.parse(JSON.stringify(this.primusRouteService.params));
    // Remove the params under to prevent unintended consequences
    delete params.artifactId;
    delete params.parentId;
    delete params.rootObjId;
    delete params.rootObjType;
    return params;
  }

  goToSearch() {
    this.primusRouterService.goBackToSearch().then();
  }

  private async initialize(user: UserData) {
    if (user) {
      this.operationContainer = await this.operationService.createOperationContainer(OperationTarget.MAIN_MENU_VIEW);

      await this.operationService.setOperations(this.operationContainer);

      this.operations.push(
        {
          name: 'TRANS__MAIN_MENU__HOME_BUTTON',
          icon: 'home',
          router_link: '/home',
          operation: null
        },
        {
          name: 'TRANS__MAIN_MENU__SEARCH_BUTTON',
          icon: 'search',
          router_link: 'search',
          operation: null
        }
      );

      for (const operation of this.operationContainer.operations) {
        if (operation.operation_type_id === 'e0dbbea0-6c5f-45b8-929d-0fbc62f7d247' ||
            operation.operation_type_id === '69b89b04-23f9-4d07-a3bf-85fd1ad6d26e' ||
            operation.operation_type_id === '6ff12fc5-e4a6-426a-993b-ad3c40c1ab2e') {
          this.operations.push({
            name: operation.menu_title,
            icon: operation.menu_icon,
            router_link: this.primusStateMapperService.routerLinkFromState(operation?.operation_steps[0]?.change_state[0].state_name),
            operation: operation
          });
        }
      }
    }
  }
}

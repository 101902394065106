import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output} from '@angular/core';
import {ToolbarAction} from '../../core/definitions/toolbar-action';
import {OperationToolbarServiceService} from '../operation-toolbar-service.service';
import {OperationContainer} from '../../core/definitions/operation-container';
import {OperationStepExecutionResult} from '../../core/definitions/operation-step-execution-result';
import {OperationStep} from "../../core/definitions/operation-step";

@Component({
  selector: 'app-operation-toolbar-dialog',
  templateUrl: './operation-toolbar-dialog.component.html',
  styleUrls: ['./operation-toolbar-dialog.component.scss']
})
export class OperationToolbarDialogComponent implements OnChanges, OnDestroy {

  @Input() operationContainer: OperationContainer;
  @Input() operationStep: OperationStep;
  @Input() selectorEnabled: boolean;
  @Input() stylingClass: string;
  @Output() operationStepExecutionResult = new EventEmitter<OperationStepExecutionResult>();

  toolbarActions: ToolbarAction[] = [];
  private registerHasBeenRun = false;
  private lastOperationStepExecutionResult: OperationStepExecutionResult;

  constructor(private operationToolbarService: OperationToolbarServiceService) {
  }

  ngOnChanges(): void {
    this.toolbarActions = this.operationStep.toolbar_actions || [];
  }

  ngOnDestroy() {
    this.operationToolbarService.clearScrollTimeout();
  }

  get isInvalid(): boolean {
    return this.operationToolbarService.getIsInvalid(this.operationContainer, this.registerHasBeenRun);
  }

  getTooltipText(action: ToolbarAction) {
    return this.operationToolbarService.getTooltipText(this.selectorEnabled, action);
  }

  isDisabled(action: ToolbarAction): boolean {
    return this.operationToolbarService.isDisabled(this.operationContainer, action, this.registerHasBeenRun);
  }

  get isCompleted(): boolean {
    return this.operationToolbarService.isCompleted(this.operationContainer);
  }

  async runAction(action: ToolbarAction) {
    const runActionResult = await this.operationToolbarService.runAction(
      this.operationContainer, action, false, this.lastOperationStepExecutionResult);
    this.registerHasBeenRun = runActionResult.registerHasBeenRun;
    this.lastOperationStepExecutionResult = runActionResult.operationStepExecutionResult;
    this.operationStepExecutionResult.emit(runActionResult.operationStepExecutionResult);
  }

  get errorCount() {
    return this.operationToolbarService.getErrorCount(this.operationContainer);
  }

  // nextError() {
  //   this.operationToolbarService.nextError(this.operationContainer);
  // }

}

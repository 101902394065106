@if (searchContainer.searchResult) {
<div class="search-filter-menu" [ngClass]="{'sideMenu': sideMenu, 'dropdownMenu': !sideMenu}">
  @if (sideMenu && searchContainer.path !== 'home') {
  <div class="search-filter-menu__top">
    <h4>
      <button mat-button class="search-filter-menu__top-close-side-menu" (click)="toggleSideFilterMenuSmallScreen()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <mat-icon class="search-filter-menu__top-icon">filter_alt</mat-icon>
      <span translate="TRANS__SEARCH__LIMIT_RESULT"></span>
      <span class="search-filter-menu__top-count">({{searchContainer.searchResult.search_count}}
        {{'TRANS__SEARCH__RESULT_COUNT' | translate}})</span>
    </h4>

    @if (showClearButton()) {
    <button class="search-filter-menu__top__clear-filter-button text-blue-hover" (click)="clearSetFilters()">
      <span>{{'TRANS__SEARCH__CLEAR_SEARCH' | translate}}</span>
      <mat-icon>close</mat-icon>
    </button>
    }
  </div>
  }

  <div class="search-filter-menu__container" [ngClass]="{'home': searchContainer.path === 'home',
       'dropdown-in-selector': !sideMenu && searchContainer.searchResultViewName === SearchResultViewNames.SELECTOR}">
    @if (sideMenu) {
    <app-search-filter-menu-path class="search-filter-menu__menu-path"
      [searchContainer]="searchContainer"></app-search-filter-menu-path>
    }

    <div class="search-filter-menu__content">
      <div class="search-filter-menu__inner-content" #searchFilterMenu>
        @if (searchContainer.queryContainer.query || pathView.menus.length > 0) {
        <ul class="search-filter-menu__menu-items">
          @for (mainMenu of pathView.menus; track mainMenu.order) {
          <li class="search-filter-menu__menu-item">
            <app-search-filter-menu-item class="search-filter-menu__menu-item-content" [ngClass]="{
              'removeFilterItem': searchContainer.searchResultViewName ===
              SearchResultViewNames.CONTENT_LIST && mainMenu.count === 0 }" [sideMenu]="sideMenu" [subMenu]="false"
              [menu]="mainMenu" [searchContainer]="searchContainer"></app-search-filter-menu-item>
            @if (pathView.menus[0].menus && mainMenu.menus.length > 0 && mainMenu.openItem) {
              <ul class="search-filter-menu__menu-item-sub-menu">
                @for (subMenu of mainMenu.menus; track subMenu.order) {
                <li class="search-filter-menu__menu-item-sub-menu-item">
                  <app-search-filter-menu-item [menu]="subMenu" [sideMenu]="sideMenu" [subMenu]="true"
                    [searchContainer]="searchContainer"></app-search-filter-menu-item>
                </li>
                }
              </ul>
            }
          </li>
          }
        </ul>
        }
        @if (searchContainer.queryContainer?.selectedQueryMenu?.query_type !== 'advanced') {
          @if (searchContainer.currentPathView.search_view.hierarchic_filter_group) {
            <div class="search-filter-menu__filter-groups">
              <app-search-filter-hierarchic class="search-filter-menu__filter-groups-item" [sideMenu]="sideMenu"
                                            [searchContainer]="searchContainer"></app-search-filter-hierarchic>
            </div>
          }
          @if (searchContainer.filtersFacets.filterGroups) {
            @for (fGroup of searchContainer.filtersFacets.filterGroups; track fGroup.title) {
              <div class="search-filter-menu__filter-groups">
                <app-search-filter-group class="search-filter-menu__filter-groups-item"
                                         [sideMenu]="sideMenu" [filterGroup]="fGroup" [searchContainer]="searchContainer"></app-search-filter-group>
              </div>
            }
          }

          <div class="search-filter-menu__filter-groups">
            @for (group of searchContainer.filtersFacets.facetRangeGroups; track group.title) {
              <app-search-facet-range
                [searchContainer]="searchContainer" [group]="group" [sideMenu]="sideMenu"
                (scrollToBottom)="scrollToBottom()"></app-search-facet-range>
            }
          </div>
        }
      </div>
    </div>

  </div>

  @if (sideMenu) {
    <app-floating-feature-toggle-switch
      feature="experimental.useNewFilterMenu"
      [featureLabel]="'TRANS__FEATURE__USE_NEW_FILTER_MENU' | translate"
      bottom="2rem"
      left="2rem"
      (afterToggle)="reloadPage()"
    >
    <span>
      {{ 'TRANS__FILTER_MENU__TRY_NEW_DESIGN' | translate }}
    </span>
    </app-floating-feature-toggle-switch>
  }
</div>
}

import {Component, Injectable, Input, OnChanges} from '@angular/core';
import {SearchHandlerService} from '../../search-handler.service';
import {AConst} from '../../../core/a-const.enum';
import {OverviewField} from '../../../core/definitions/object-view';
import {SearchObject} from '../../../core/definitions/search-object';
import {SearchResultSelectionsService} from '../../search-result-selections.service';
import {SearchViewListService} from '../../search-view-list.service';
import {SearchContainer} from '../../../core/definitions/search-container';
import {MetaTypes} from '../../../core/definitions/meta-types';
import {FlatTreeControl} from '@angular/cdk/tree';
import {HierarchicSearchService} from '../../hierarchic-search.service';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {CollectionViewer, SelectionChange} from '@angular/cdk/collections';
import {map} from 'rxjs/operators';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {RefData} from '../../../core/ref.service';
import {ResultViewService} from '../../result-view.service';
import {MetaField} from "../../../core/definitions/meta-field";
import {FeatureFlagsService} from '../../../core/feature-flags.service';

export class ListFlatNode {
  name: string;
  artifact_id: string;
  used: boolean;
  refData: RefData;
  icon: string;
  meta_type: string;
  is_leaf: boolean;

  constructor(public item: SearchObject,
              public level = 1,
              public expandable = false,
              public isLoading = false) {
    this.name = item.$$name;
    this.artifact_id = item.artifact_id;
    this.used = item.$$used;
    this.refData = item.$$refData;
    this.icon = item.$$icon;
    this.meta_type = item.meta_type;
    this.is_leaf = item.is_leaf;
  }
}

@Injectable()
export class ListDatabase {
  constructor(private hierarchicSearchService: HierarchicSearchService) {
  }
  initialData(searchObjects: SearchObject[]): ListFlatNode[] {
    return searchObjects.map(searchObject => new ListFlatNode(searchObject, 1, true));
  }

  async getChildren(searchContainer: SearchContainer, node: SearchObject): Promise<SearchObject[]> {
    const children = await this.hierarchicSearchService.getChildren(searchContainer, node);
    node.$$hasChildren = !!children.length;
    return children;
  }

  isExpandable(node: SearchObject) {
    let res = ['folder', 'place'].indexOf(node.object_type) !== -1;
    if (node.$$hasChildren !== undefined) {
      res = node.$$hasChildren;
    }
    return res;
  }
}

@Injectable()
export class ListDataSource extends MatTreeFlatDataSource<any, any> {
  dataChange = new BehaviorSubject<ListFlatNode[]>([]);

  get data(): ListFlatNode[] {
    return this.dataChange.value;
  }

  set data(value: ListFlatNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(private treeControl: FlatTreeControl<ListFlatNode>,
              private treeFlattener: MatTreeFlattener<any, any>,
              private database: ListDatabase,
              private searchContainer: SearchContainer) {
    super(treeControl, treeFlattener);
  }

  connect(collectionViewer: CollectionViewer): Observable<ListFlatNode[]> {
    this.treeControl.expansionModel.changed.subscribe(change => {
      if (change.added || change.removed) {
        this.handleTreeControl(change);
      }
    });
    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  handleTreeControl(change: SelectionChange<ListFlatNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
    }
  }

  async toggleNode(node: ListFlatNode, expand: boolean): Promise<void> {
    node.isLoading = true;
    const children = await this.database.getChildren(this.searchContainer, node.item);
    const index = this.data.findIndex(flatNode => flatNode.artifact_id === node.artifact_id);
    if (!children.length || index < 0) {
      return;
    }

    const nodes = children.map(searchObject => new ListFlatNode(searchObject, node.level + 1, this.database.isExpandable(searchObject)));
    if (expand) {
      this.data.splice(index + 1, 0, ...nodes);
    } else {
      for (const node of nodes) {
        await this.toggleNode(node, false);
      }
      this.data.splice(index + 1, children.length);
    }
    this.dataChange.next(this.data);
    node.isLoading = false;
  }
}

@Component({
  selector: 'app-search-result-view-list',
  templateUrl: './search-result-view-list.component.html',
  styleUrls: ['./search-result-view-list.component.scss'],
  providers: [ListDatabase]
})
export class SearchResultViewListComponent implements OnChanges {

  @Input() searchContainer: SearchContainer;

  MetaTypes = MetaTypes;
  AConst = AConst;
  objectFields: {[name: string]: OverviewField[]};
  displayedCol: string[] = [];
  treeControl: FlatTreeControl<ListFlatNode>;
  dataSource: ListDataSource;
  artifactNameField = {field_name: AConst.ARTIFACT_NAME} as MetaField;

  private subTitleCounter = {count: 0};
  private transformer = (node: SearchObject, level: number) => {
    return {
      expandable: false, //node.$$hasChildren === undefined || node.$$hasChildren,
      level: level,
      item: node,
      name: node.$$name,
      artifact_id: node.artifact_id,
      used: node.$$used,
      refData: node.$$refData,
      icon: node.$$icon,
      meta_type: node.meta_type,
      is_leaf: node.is_leaf
    };
  }

  readonly objectPageV2 = this.featureFlags.getFeatureFlags().experimental.useNewObjectPage;
  constructor(
    private database: ListDatabase,
    private searchViewListService: SearchViewListService,
    private searchHandler: SearchHandlerService,
    private searchResultSelectionsService: SearchResultSelectionsService,
    private resultViewService: ResultViewService,
    private featureFlags: FeatureFlagsService) {
  }

  getLevel = (node: ListFlatNode) => node.level;

  isExpandable = (node: ListFlatNode) => {
    return false;
    // if (!!node.is_leaf) {
    //   return false;
    // } else {
    //   return this.database.isExpandable(node.item);
    // }
  }

  getChildren = (node: ListFlatNode) => {
    return this.getChildren(node);
  }

  ngOnChanges(): void {
    this.init().then();
  }

  onOperationPerformed(event: any) {
    console.log('Event: ' + event);
  }

  setOrder(fieldInfo: MetaField) {
    if (fieldInfo.field_name === 'artifact_name' || fieldInfo.isSortable) {
      this.searchHandler.setOrder(this.searchContainer, fieldInfo.field_name, fieldInfo);
    }
  }

  select(element: ListFlatNode, event: any) {
    this.searchResultSelectionsService.selectSearchResultItem(element.item, this.searchContainer, event.shiftKey);
  }

  setTitles() {
    for (const field of this.searchContainer.currentPathView.search_view.overview_fields) {
      field.$$fieldTitle = this.searchViewListService.getTitle(field, this.subTitleCounter);
    }
  }

  getObjectFields(element: ListFlatNode): Array<OverviewField> {
    const art = element.item;
    const objectId = art.artifact_id;
    let res: OverviewField[] = this.objectFields[objectId];
    if (!res) {
      /* In some cases, the search result is changed after objectFields is set, causing some issues*/
      this.objectFields[objectId] = art.overview;
      res = this.objectFields[objectId];
    }
    for (const field of res) {
      this.searchViewListService.setFieldColumnName(field);
    }
    return res;
  }

  setSearchItemIndex(searchItemIndex: number) {
    if (this.searchContainer && searchItemIndex !== undefined) {
      this.searchHandler.setSearchItemIndex(this.searchContainer, searchItemIndex);
    }
  }

  private async init() {
    this.treeControl = new FlatTreeControl<ListFlatNode, ListFlatNode>(this.getLevel, this.isExpandable);
    const treeFlattener = new MatTreeFlattener(
      this.transformer, node => node.level, node => node.expandable, () => []);
    this.dataSource = new ListDataSource(this.treeControl, treeFlattener, this.database, this.searchContainer);
    await this.checkSetSearchResultItemProps();
    this.dataSource.data = this.database.initialData(this.searchContainer.searchResult.artifacts);


    this.objectFields = {};
    this.searchViewListService.initObjectFields(this.searchContainer, this.objectFields);
    this.searchViewListService.setDisplayedColumns(this.searchContainer, this.displayedCol);
    this.setTitles();
    this.displayedCol.push('setting');

    this.searchContainer.newScrollItemsCallback = (newItems: any[]) => {
      this.dataSource.data = this.dataSource.data.concat(newItems.map(item => new ListFlatNode(item)));
    };
  }

  private async checkSetSearchResultItemProps() {
    const searchObjects = this.searchContainer.searchResult.artifacts;
    if (searchObjects.length && !searchObjects[0].$$refData) {
      // This condition should actually never happen. But somehow the testers have reported errors probably due to
      // missing $$refData so that's the reason for this function.
      await this.resultViewService.setSearchResultItemProps(this.searchContainer, searchObjects);
    }
  }

  getRouterLink(art: ListFlatNode) {
    if (art.refData?.routerLink?.join('/')?.includes('/artifact') && this.objectPageV2) {
      return [...art.refData.routerLink, art.artifact_id];
    }
    return art.refData?.routerLink
  }
}

<div class="user-profile light-gray-bg-color" [ngClass]="{'deactivated': user.deactivated}">
  <header class="user-profile__header">
    <button mat-stroked-button
            type="button"
            class="back-button"
            (click)="handleBackButtonClicked()">
      <mat-icon>arrow_back</mat-icon>
      {{ 'TRANS__PROFILE__BACK_BUTTON_LABEL' | translate }}</button>

    @if (useNewMainMenu) {
        <button mat-raised-button class="user-profile-logout" (click)="logOut()">
          <mat-icon>
            logout
          </mat-icon>
          <span>
            {{ 'TRANS__HEADER__LOG_OUT' | translate }}
          </span>
        </button>
    }
    <img class="header-image" [src]="headerImageUrl" [alt]="'TRANS__PROFILE__USER_BACKGROUND_IMAGE' | translate">
    <div class="deactivated-header-gradient"></div>
  </header>

  <article class="profile-image">
    <div class="profile-image__image mat-elevation-z4">
      <img [src]="profileImageUrl" *ngIf="profileImageUrl" [alt]="'TRANS__PROFILE__USER_IMAGE' | translate">
      <div class="profile-image__overlay"
           role="button"
           *ngIf="isAdmin || allowProfileImageChange"
           (click)="handleChangeProfilePhotoClicked()">
        <mat-icon *ngIf="profileImageUrl">edit</mat-icon>
        <mat-icon *ngIf="!profileImageUrl">add_a_photo</mat-icon>
      </div>
    </div>

    <h1 class="profile-image__name">{{ user.first_name }} {{ user.last_name }}</h1>

    <mat-chip-listbox class="profile-image__role" [selectable]="false">
      <mat-chip-option [selectable]="false">
        {{ user.rights_level }}
      </mat-chip-option>
      <mat-chip-option [selectable]="false" *ngIf="this.isECultureUser()">
        {{ 'TRANS__PROFILE__E_CULTURE_USER' | translate}}
      </mat-chip-option>
    </mat-chip-listbox>
  </article>

  <article class="profile-user-info">
    <app-user-profile-section
      heading="TRANS__PROFILE__USER_INFO"
      [showEditButton]="isAdmin"
      (saveClicked)="handleSaveClicked()"
      (abortClicked)="handleCancelClicked()"
      (editModeChanged)="editUserInfo = $event">
      <app-user-info-section [sectionsContainer]="sectionsContainer" [enableEditMode]="isAdmin && editUserInfo"></app-user-info-section>
    </app-user-profile-section>
  </article>

  <article class="profile-collection-access">
    <app-user-profile-section
      heading="TRANS__PROFILE__ACCESS_COLLECTION"
      name="collections"
      [showEditButton]="isAdmin"
      (saveClicked)="handleSaveClicked()"
      (abortClicked)="handleCancelClicked()"
      (addCollectionRightsClicked)="handleAddCollectionRightsClicked($event)"
      (editModeChanged)="editCollectionRights = $event">
      <app-user-collections
        *ngIf="changedUser"
        [user]="changedUser"
        [collections]="changedUser.collections"
        collectionType="collections"
        [enableEditMode]="isAdmin && editCollectionRights"
      ></app-user-collections>
    </app-user-profile-section>
  </article>

  <article class="profile-virtual-collection-access">
    <app-user-profile-section
      heading="TRANS__PROFILE__ACCESS_VIRTUAL_COLLECTION"
      name="virtual_collections"
      [showEditButton]="isAdmin"
      (saveClicked)="handleSaveClicked()"
      (abortClicked)="handleCancelClicked()"
      (addCollectionRightsClicked)="handleAddCollectionRightsClicked($event)"
      (editModeChanged)="editVirtualCollectionRights = $event">
      <app-user-collections
        *ngIf="changedUser"
        [collections]="changedUser.virtual_collections"
        collectionType="virtual_collections"
        [enableEditMode]="isAdmin && editVirtualCollectionRights"
      ></app-user-collections>
    </app-user-profile-section>
  </article>

  <article class="profile-rights">
    <app-user-profile-section
      heading="TRANS__PROFILE__ACCESS_RIGHTS"
      name="rights"
      [showEditButton]="isAdmin"
      (saveClicked)="handleSaveClicked()"
      (abortClicked)="handleCancelClicked()"
      (editModeChanged)="editUserRights = $event">
      <app-access-rights-section *ngIf="changedUser" [user]="changedUser" [enableEditMode]="isAdmin && editUserRights"></app-access-rights-section>
    </app-user-profile-section>
  </article>
</div>

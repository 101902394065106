<div class="filter-group">
  @if(filterGroup) {
    @if (hasTooManyFilters) {
      <mat-form-field
        appearance="outline"
        class="filter-group__query-form"
      >
        <input
          matInput
          class="filter-group__query-input"
          placeholder="{{ 'TRANS__FILTER_MENU__SEARCH_IN_COUNT' | translate:{count: filterGroup.checkFilters.length} }} {{(filterGroup.title | translate).toLowerCase()}}"
          type="text"
          (ngModelChange)="filterQueryChanged()"
          [(ngModel)]="filterGroup.filterFilter.query"
        />

        <mat-icon matSuffix>
          search
        </mat-icon>
      </mat-form-field>
    }

    @for(filter of filterGroup.visibleFilters; track filter) {
      <div class="filter-group__item">
        <mat-checkbox
          class="filter-group__item__checkbox"
          (click)="checkFilter(filter)"
          [(ngModel)]="filter.checked"
        >
          @if (filter.title !== '' && filter.title !== null && filter.title !== undefined) {
            <span class="filter-group__item__label--body-medium-grow">
              {{filter.title | translate}}
            </span>
          }
          @else {
            <span class="filter-group__item__label--body-medium-grow">
              {{filter.noTransTitle}}
            </span>
          }

          @if (filter.count) {
            <span class="filter-group__item__label--label-small filter-group__item__label--subtle">
              {{filter.count}}
            </span>
          }
        </mat-checkbox>
      </div>
    }

    @if (filterGroup.filterFilter?.hasMore) {
      <div class="filter-group__show-more">
        <button mat-button class="filter-group__show-more-button" (click)="showMoreFilters()">
          {{ 'TRANS__FILTER_MENU__SHOW_COUNT_MORE' | translate:{count: showMoreCount} }}
          <mat-icon>
            expand_more
          </mat-icon>
        </button>
      </div>
    }
  }
  @if (subCategories) {
    @for(subCategory of subCategories(); track $index) {
      <div class="filter-group__item">
        <mat-checkbox
          class="filter-group__item__checkbox"
          (click)="checkSubCategory(subCategory)"
        >
          <span class="filter-group__item__label--body-medium-grow">
            {{ subCategory.label() | translate}}
          </span>

          @if (subCategory.count) {
            <span class="filter-group__item__label--label-small filter-group__item__label--subtle">
              {{subCategory.count}}
            </span>
          }
        </mat-checkbox>
      </div>
    }
  }
</div>

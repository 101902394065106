import {Component, Input, OnChanges, signal, WritableSignal} from '@angular/core';
import {SearchContainer} from "../../../../core/definitions/search-container";
import {CheckFilter, CheckFilterGroup} from "../../../../core/definitions/search-objects";
import {TranslateModule} from "@ngx-translate/core";
import {SearchFilterService} from "../../../search-filter.service";
import {SearchResultViewType} from "../../../../core/definitions/search-result-view-type.enum";
import {MatCheckbox} from "@angular/material/checkbox";
import {FormsModule} from "@angular/forms";
import {CategoryMenuItem} from "../../filter-menu-category-selector/filter-menu-category-selector.component";
import {MatFormField, MatInput, MatSuffix} from "@angular/material/input";
import {MatIcon} from "@angular/material/icon";
import {AConst} from "../../../../core/a-const.enum";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-filter-group',
  standalone: true,
  imports: [
    TranslateModule,
    MatCheckbox,
    FormsModule,
    MatInput,
    MatFormField,
    MatIcon,
    MatSuffix,
    MatButton,
  ],
  templateUrl: './filter-group.component.html',
  styleUrl: './filter-group.component.scss'
})
export class FilterGroupComponent implements OnChanges {
  @Input() subCategories: WritableSignal<CategoryMenuItem[]>;
  @Input() filterGroup: CheckFilterGroup;
  @Input() searchContainer: SearchContainer;

  constructor(
    private readonly searchFilterService: SearchFilterService
  ) {}

  ngOnChanges() {
    if(this.filterGroup) {
      if (this.searchContainer.searchResultViews.default === SearchResultViewType.SELECTOR ||
        this.searchContainer.searchResultViews.default === SearchResultViewType.CONTENT_LIST) {
        this.filterGroup.enabled = false;
      }
      else {
        if (!this.filterGroup.enabled) {
          this.filterGroup.enabled = !! this.filterGroup.filters.find((filter) => filter.checked_value !== undefined);

          if (this.filterGroup.enabled) {
            this.filterGroup.active = true;
          }
        }
      }
    }
  }

  checkFilter(filter: CheckFilter) {
    this.searchFilterService.checkCheckFilters([filter], this.searchContainer, false).then(() => {
      this.searchFilterService.setVisibleFilters(this.filterGroup);
    })
  }

  checkSubCategory(category: CategoryMenuItem) {
    this.searchFilterService.checkMenuFilter(category.menu, this.searchContainer, false).then();
  }

  filterQueryChanged() {
    setTimeout(() => {
      this.filterGroup.filterFilter.showMoreCount = 10;
      this.searchFilterService.setVisibleFilters(this.filterGroup);
    }, 50);
  }

  showMoreFilters() {
    this.filterGroup.filterFilter.showMoreCount += this.filterGroup.filterFilter.showMoreAdd;
    this.searchFilterService.setVisibleFilters(this.filterGroup);
  }

  get hasTooManyFilters(): boolean {
    return this.filterGroup?.checkFilters?.length > AConst.MAX_FILTER_LENGTH;
  }

  get showMoreCount(): number {
    if (this.filterGroup.filterFilter.showMoreAdd > (this.filterGroup.filterFilter.filterCount - this.filterGroup.filterFilter.showMoreCount)) {
      return this.filterGroup.filterFilter.filterCount - this.filterGroup.filterFilter.showMoreCount
    }
    else {
      return this.filterGroup.filterFilter.showMoreAdd;
    }
  }
}

<div class="category-selector__container">
  <span class="category-selector__header">
    {{ searchContainer.queryContainer.queryPlaceholder | translate }}
  </span>

  <div
    matRipple
    class="category-selector__button"
    [matMenuTriggerFor]="categoriesMenu"
    [ngClass]="{ 'category-selector__button--loading': loading }"
  >
    @if (loading) {
      <mat-spinner diameter="24" />
      <span class="category-selector__button-label category-selector__button-label--loading">
        {{ 'TRANS__FILTER_MENU__CATEGORIES_FETCHING' | translate }}
      </span>
    }
    @else {
      <mat-icon class="category-selector__button-icon">
        @if (selectedCategory?.icon) {
          {{ selectedCategory?.icon }}
        }
        @else {
          category
        }
      </mat-icon>
      <span class="category-selector__button-label">
       {{ selectedCategory?.label() }}
      </span>
      @if(selectedCategory?.path !== 'home') {
        <mat-icon (click)="clearSelectedCategory($event)">
          clear
        </mat-icon>
      }
      <mat-icon>
        arrow_drop_down
      </mat-icon>
    }

    <mat-menu #categoriesMenu="matMenu"  class="category-selector__mat-list-and-menu-overrides">
      <app-filter-menu-category-selector-dropdown
        [categories]="categories"
        [loading]="trigger"
        [searchContainer]="searchContainer"
        [selected]="selectedCategory"
        (selectedCategory)="categorySelected($event)"
      ></app-filter-menu-category-selector-dropdown>
    </mat-menu>
  </div>
</div>

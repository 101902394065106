import {Component, Inject, OnInit, signal, WritableSignal} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OperationViewDialogData} from "../operation-view-dialog/operation-view-dialog.component";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {OperationContainer} from "../../core/definitions/operation-container";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {MatFormField, MatSuffix, MatLabel, MatPrefix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {DamsService} from "../../core/dams.service";
import {MatOption, MatSelect} from "@angular/material/select";
import {PrimusBackendInstanceService} from "../../core/primus-backend-instance.service";
import {CmsApiService} from "../../core/cms-api.service";
import {
  OperationViewDamsDialogItemComponent
} from "./operation-view-dams-dialog-item/operation-view-dams-dialog-item.component";
import {FormsModule} from "@angular/forms";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {NgClass} from "@angular/common";
import {MatRipple} from "@angular/material/core";
import {
  DamsExtended,
  OperationViewDamsDialogEditItemComponent
} from "./operation-view-dams-dialog-edit-item/operation-view-dams-dialog-edit-item.component";
import {DamsImportItem} from "../../core/definitions/dams-import-data";
import {OperationService} from "../operation.service";

export interface DamsGridItem {
  $$hasImage: boolean;
  id: string;
  type: string;
  title: string;
  dmsf_id?: string;
  created_at: string;
  created_by: string;
  selected: boolean;
  solr_object: Object;
  operationData: DamsImportItem;
  extended_metadata?: DamsExtended;
}

@Component({
  selector: 'app-operation-view-dams-dialog',
  standalone: true,
  imports: [
    TranslateModule,
    MatIconButton,
    MatIcon,
    MatFormField,
    MatInput,
    MatSuffix,
    MatSelect,
    MatOption,
    MatLabel,
    MatButton,
    MatPrefix,
    OperationViewDamsDialogItemComponent,
    FormsModule,
    MatPaginator,
    MatProgressSpinner,
    NgClass,
    MatRipple,
    OperationViewDamsDialogEditItemComponent
  ],
  templateUrl: './operation-view-dams-dialog.component.html',
  styleUrl: './operation-view-dams-dialog.component.scss'
})
export class OperationViewDamsDialogComponent implements OnInit {
  loading: boolean = false;
  operationContainer: OperationContainer;
  pageNumber: number = 0;
  previousIndex = null;
  searchQuery: string = '';
  selectedItems: DamsGridItem[] = [];
  selectedType: string = 'all';
  sorting: string = 'created_at desc';
  step: number = 1;
  errorMessage = '';

  queryTimeout = null;

  imageUrls: WritableSignal<Object> = signal<Object>({});
  searchResults: WritableSignal<DamsGridItem[]> = signal<DamsGridItem[]>([]);
  totalCount: WritableSignal<number> = signal<number>(0);

  constructor(
    public dialogRef: MatDialogRef<OperationViewDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: OperationViewDialogData,
    public damsService: DamsService,
    private readonly cmsApi: CmsApiService,
    private readonly operationService: OperationService,
    private readonly translate: TranslateService
  ) {
  }

  ngOnInit() {
    // this.createDamsImportOperation();
    this.step = 1;
    this.selectedItems = [];

    this.operationContainer = this.data.operationContainer;
    this.operationContainer.currentOperation.$$operationDialog = this.dialogRef;
    this.search();
  }

  batchToggleItems(data) {
    if (data.state && !this.selectedItems.includes(this.searchResults()[data.index])) {
      this.selectedItems.push(this.searchResults()[data.index]);
    }

    if (!data.state && this.selectedItems.includes(this.searchResults()[data.index])) {
      let index = this.selectedItems.findIndex(item => item.id === this.searchResults()[data.index].id);
      this.selectedItems.splice(index, 1);
    }

    if (this.previousIndex === null || !data.shift) {
      this.searchResults.update(state => {
        state[data.index].selected = data.state

        return state;
      })
    } else {
      this.searchResults.update(state => {
        for (let i = data.index > this.previousIndex ? this.previousIndex : data.index; i <= data.index > this.previousIndex ? data.index : this.previousIndex; i++) {
          state[i].selected = data.state;
        }

        this.previousIndex = data.index;

        return state;
      })
    }
  }

  clearSelected() {
    this.selectedItems = [];

    for (const item of this.searchResults()) {
      item.selected = false;
    }
  }

  closeDialog() {
    if (this.step === 2) {
      this.step = 1;
    } else {
      this.dialogRef.close();
    }
  }

  getImage(dmsf_id: string) {
    return this.imageUrls().hasOwnProperty(dmsf_id) ? this.imageUrls()[dmsf_id] : {};
  }

  nextStep() {
    if (this.step === 1) {
      this.errorMessage = '';
      this.step = 2;
      this.loading = true;
      this.getSelectedMetadata().then((result) => {
        let metadata = [];
        for (const key of Object.keys(result)) {
          metadata.push(result[key]);
        }
        return this.cmsApi.createImportDataFromDamsMetadata({ metadata });
      }).then((result) => {
        for (const item of this.selectedItems) {
          let index = result.import_items.findIndex(i => i.unique_id === item.id);
          if (index > -1) {
            item.operationData = result.import_items[index];
          }
        }

        this.loading = false;
      }).catch((reason: any) => {
        this.loading = false;
        this.errorMessage = this.translate.instant('TRANS__ERROR__GETTING_DAMS_METADATA') + ': ' + reason.message;
      });
    } else if (this.step === 2) {
      this.executeDamsImportOperation().then();
    }
  }

  pageChange(event: PageEvent) {
    this.pageNumber = event.pageIndex;
    this.search();
  }

  previousStep() {
    if (this.step === 2) {
      this.step = 1;
    }
  }

  removeSelectedItem(item: DamsGridItem) {
    let index = this.selectedItems.findIndex(i => i.id === item.id);
    this.selectedItems.splice(index, 1);

    for (const i of this.searchResults()) {
      if (i.id === item.id) {
        i.selected = false;
      }
    }

    if (this.selectedItems.length === 0) {
      this.step = 1;
    }
  }

  search() {
    this.errorMessage = '';
    this.loading = true;
    this.searchResults.set([]);

    this.damsService.search({
      q: this.searchQuery !== '' ? this.searchQuery : '*:*',
      museums: PrimusBackendInstanceService.getInstanceDetails().id.toString(),
      document_type: this.selectedType === 'all' ? this.damsService.allowedTypes : [this.selectedType],
      start: this.pageNumber * 40,
      rows: 40,
      sort: this.sorting
    }).then((result: any) => {
      let params = {
        dms_ids: [],
        sizes: []
      }
      if (result.objects) {
        this.totalCount.set(result.count);
        for (const item of result.objects) {
          this.searchResults.update(current => [...current, {
            $$hasImage: true,
            id: item.unique_id,
            type: item.document_type,
            title: item.title,
            dmsf_id: item.dmsf_id || null,
            created_at: item.created_at,
            created_by: item.created_by_name,
            selected: this.selectedItems.findIndex(i => i.id === item.unique_id) !== -1,
            solr_object: item,
            operationData: null
          }]);

          params.dms_ids.push(item.dmsf_id);
          params.sizes.push('medium');
        }
      }

      this.loading = false;

      if (this.searchResults().length > 0) {
        return this.cmsApi.getImageUrlsByDmsIds(params);
      }
    }).then((result: any) => {
      this.imageUrls.set(result ? result : {});
    }).catch((reason: any) => {
      this.loading = false;
      this.errorMessage = this.translate.instant('TRANS__ERROR__SEARCHING_DAMS') + ': ' + reason.message;
    })
  }

  selectType(type: string) {
    this.selectedType = type;
    this.pageNumber = 0;
    this.search();
  }

  toggleSorting() {
    if (this.sorting === 'created_at desc') {
      this.sorting = 'created_at asc';
    } else {
      this.sorting = 'created_at desc';
    }

    this.search();
  }

  get query(): string {
    return this.searchQuery;
  }

  set query(value: string) {
    this.searchQuery = value;

    if (this.queryTimeout !== null) {
      clearTimeout(this.queryTimeout);
    }

    this.queryTimeout = setTimeout(() => {
      this.pageNumber = 0;
      this.search();
    }, 500);
  }

  private async executeDamsImportOperation() {
    this.operationService.goNextOperationStep(this.operationContainer);
    const operationObjects = this.selectedItems.map(item => {
      return item.operationData
    });
    const stepExecutionParams = this.operationService.generateOperationStepExecutionParamsOnCustomOperationObjects(
      this.operationContainer, operationObjects);
    const res = await this.operationService.executeOperationStep(
      this.operationContainer, stepExecutionParams);
    console.log(res);
  }

  private async getSelectedMetadata() {
    let metadata = {};

    for (const item of this.selectedItems) {
      metadata[item.id] = await this.damsService.getMetaData(item.id);
      item.extended_metadata = metadata[item.id];
    }

    return metadata;
  }
}

<div class="dialog operation-view-dialog" *ngIf="operationContainer.currentOperation.$$showOperationView">
  <div mat-dialog-title class="modal-header">
    <h1 class="modal-title">
      <span>{{dialogTitle}}</span>
    </h1>
    <button type="button"
            mat-icon-button
            class="close"
            [tabindex]="-1"
            aria-label="close dialog"
            mat-dialog-close="">
      <mat-icon>close</mat-icon></button>
  </div>

  <div mat-dialog-content class="modal-body modal-body-edit-fields">
    <div class="modal-main-content">
      <app-operation-view-information
        [operationContainer]="operationContainer"
        [operationStepIndex]="operationContainer.currentOperation.$$currentStepIndex"
      ></app-operation-view-information>
      <app-operation-view-section-container
        *ngIf="!operationContainer.currentOperation.upload_info"
        [operationContainer]="operationContainer"
        [operationStepIndex]="operationContainer.currentOperation.$$currentStepIndex"
        [isDialog]="true"
      ></app-operation-view-section-container>
      <app-operation-view-upload
        *ngIf="operationContainer.currentOperation.upload_info"
        [operationContainer]="operationContainer"
        [operationStepIndex]="operationContainer.currentOperation.$$currentStepIndex"
      ></app-operation-view-upload>
      <div *ngIf="operationStepExecutionResult && operationStepExecutionResult.status === 'failed'" class="operation-view-dialog__failed">
        <span *ngFor="let errorMessage of errorMessages">{{errorMessage | translate}}</span>
      </div>
    </div>
  </div>

  <div mat-dialog-actions class="modal-footer">
    <app-operation-toolbar-dialog
      class="operation-view-dialog__toolbar_container"
      [operationContainer]="operationContainer"
      [operationStep]="operationContainer.currentOperation.$$currentStep"
      [selectorEnabled]="operationContainer.selectorEnabled"
      (operationStepExecutionResult)="onOperationStepExecutionResult($event)"
    ></app-operation-toolbar-dialog>
  </div>
</div>

import { Component, Input } from '@angular/core';
import {SearchObject} from "../../../core/definitions/search-object";
import {SearchContainer} from "../../../core/definitions/search-container";
import {MatCheckbox} from "@angular/material/checkbox";
import {NgClass} from "@angular/common";
import {SearchResultSelectionsService} from "../../search-result-selections.service";
import {objectTypeToMatSymbol} from "../../../shared/material-icons";
import {MatIcon} from "@angular/material/icon";
import {FeatureFlagsService} from "../../../core/feature-flags.service";
import {RouterLink} from "@angular/router";
import {OverviewFieldsModule} from "../../../overview-fields/overview-fields.module";

@Component({
  selector: 'app-search-result-view-thumbnail-list-v2',
  standalone: true,
  imports: [
    MatCheckbox,
    NgClass,
    MatIcon,
    RouterLink,
    OverviewFieldsModule
  ],
  templateUrl: './search-result-view-thumbnail-list-v2.component.html',
  styleUrl: './search-result-view-thumbnail-list-v2.component.scss'
})
export class SearchResultViewThumbnailListV2Component {
  @Input() item: SearchObject;
  @Input() searchContainer: SearchContainer;

  constructor(
    private readonly featureFlagService: FeatureFlagsService,
    private readonly searchResultSelectionService: SearchResultSelectionsService
  ) {}

  getDisplayFields(item: SearchObject) {
    if (item.$$columns) {
      return item.$$columns;
    }

    const data = [];
    const overviewFields = item.overview;

    if (overviewFields) {
      for(const field of overviewFields) {
        data.push(field);
      }

      item.$$columns = data;

      if (data.length === 0) {
        console.warn('No columns set');
      }
    }

    return data;
  }

  getRouterLink(item: SearchObject) {
    if (item.$$refData?.routerLink?.join('/')?.includes('/artifact') && this.objectPageV2) {
      return [...item.$$refData.routerLink, item.artifact_id];
    }
    return item.$$refData?.routerLink
  }

  getSymbolFromObjectType(objectType: string) {
    return objectTypeToMatSymbol(objectType);
  }

  select(item: SearchObject, event: MouseEvent) {
    event.stopPropagation();
    this.searchResultSelectionService.selectSearchResultItem(item, this.searchContainer, event.shiftKey);
  }

  readonly objectPageV2 = this.featureFlagService.getFeatureFlags()?.experimental.useNewObjectPage;
}

<div class="admin-users">
  <app-top-header [parent]="parent"
                  backRouterLink="/admin"
                  backRouterLinkLabel="TRANS__SECTION__ADMIN__TITLE"
                  class="admin-header-container"></app-top-header>
  <app-header-and-description heading="TRANS__ADMIN__USERS__HEADING"
                              description="TRANS__ADMIN__USERS__DESCRIPTION">

    <div class="admin-users__content">

      <app-admin-users-filter class="admin-content-top admin-users__top"
                              (filterChanged)="handleFilterChanged($event)"
                              (listTypeChanged)="handleListTypeChanged($event)"
                              (actionEvent)="handleActionOnSelectedUsers($event)"
                              [selectedUsers]="selectedUsers">
      </app-admin-users-filter>

      @if (dataSource) {
        <app-primus-table class="admin-users__primus-table"
                          [dataSource]="dataSource"
                          [columns]="columns"
                          [sortableColumns]="false"
                          [selectable]="true"
                          [editable]="false"
                          [rowActions]="true"
                          (addNewRowClicked)="handleCreateUserClicked()"
                          [actionButtonDisabled]="!isAdmin"
                          [actionButtonName]="'TRANS__ADMIN__USERS__CREATE_USER_BUTTON_LABEL'"
                          (selectionChanged)="handleSelectionChanged($event)"
                          (editClicked)="handleEditUserClicked($event)"
                          (pageChanged)="handlePageChange($event)">
        </app-primus-table>
      }

    </div>

  </app-header-and-description>
</div>

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UiToolsService} from '../../core/ui-tools.service';
import {SearchSelectorService} from '../../core/search-selector.service';
import {SelectorContainer} from '../../core/definitions/selector-container';
import {SearchParameterService} from '../search-parameter.service';
import {SearchExecutorService} from '../search-executor.service';
import {SearchTemplateGroupService} from '../search-template-group.service';
import {SearchContainer} from '../../core/definitions/search-container';

@Component({
  selector: 'app-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.scss']
})
export class SearchHeaderComponent implements OnInit, OnDestroy {
  @Input() searchContainer: SearchContainer;
  @Input() searchPlaceholder: string;
  @Input() sideMenuSmallScreen;
  @Output() toggleSideMenuSmallScreen = new EventEmitter<object>();

  newLayout = true;
  setSearchQueryFocus = false;
  mediumScreen: boolean;
  templateSelectorContainer: SelectorContainer;
  curSelectorContainer: SelectorContainer;
  private stopSizeWatch;

  constructor(private readonly uiTools: UiToolsService,
              private readonly selectorHandler: SearchSelectorService,
              private readonly searchExecutorService: SearchExecutorService,
              private readonly searchParameterService: SearchParameterService,
              private readonly searchTemplateGroupService: SearchTemplateGroupService) {
  }

  ngOnInit() {
    this.mediumScreen = this.uiTools.setMediumScreenSizeOnLoad(this.mediumScreen);
    this.stopSizeWatch = this.uiTools.addWindowSizeListener(newVal => {
        if (newVal) {
          this.mediumScreen = newVal.width < 1025;
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.stopSizeWatch) {
      this.uiTools.removeWindowSizeListener(this.stopSizeWatch);
    }
  }

  toggleSideFilterMenuSmallScreen() {
    this.toggleSideMenuSmallScreen.emit();
  }

  selectorOverlay() {
    if (this.templateSelectorContainer && this.templateSelectorContainer.selectorEnabled) {
      this.selectorHandler.disableSelector(this.templateSelectorContainer);
    }
    if (this.searchContainer.selectorContainer && this.searchContainer.selectorContainer.selectorEnabled) {
      this.selectorHandler.disableSelector(this.searchContainer.selectorContainer);
    }
    if (this.searchContainer.operationContainer && this.searchContainer.operationContainer.selectorEnabled) {
      this.selectorHandler.disableSelector(this.searchContainer.operationContainer);
    }
    if (this.searchContainer.operationContainerDropDown && this.searchContainer.operationContainerDropDown.selectorEnabled) {
      this.selectorHandler.disableSelector(this.searchContainer.operationContainerDropDown);
    }
  }

  onTemplateSelectorCreated(templateSelectorContainer) {
    this.templateSelectorContainer = templateSelectorContainer;
  }

  onChangeTemplateGroup(newGroupId) {
    this.searchTemplateGroupService.setTemplateGroup(this.searchContainer, newGroupId);
    this.searchExecutorService.runSearch(this.searchContainer).then();
  }

  isSearchViewSelectorEnabled() {
    let res = this.templateSelectorContainer && this.templateSelectorContainer.selectorEnabled;
    if (res) {
      this.curSelectorContainer = this.templateSelectorContainer;
    }
    if (!res) {
      res = !!this.searchContainer.selectorContainer;
      if (res) {
        this.curSelectorContainer = this.templateSelectorContainer;
      }
    }
    if (!res) {
      res = !!this.searchContainer.operationContainer?.selectorEnabled;
      if (res) {
        this.curSelectorContainer = this.searchContainer.operationContainer;
      }
    }
    if (!res) {
      res = !!this.searchContainer.operationContainerDropDown?.selectorEnabled;
      if (res) {
        this.curSelectorContainer = this.searchContainer.operationContainerDropDown;
      }
    }
    if (!res) {
      this.curSelectorContainer = null;
    }
    return res;
  }
}

<mat-dialog-content>
  {{ 'TRANS__OPERATIONS__ONLY_NEW_OBJECT_VIEW' | translate }}
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">
    {{ 'TRANS__BUTTON__CLOSE' | translate }}
  </button>
  <button mat-flat-button [mat-dialog-close]="true" cdkFocusInitial>
    {{ 'TRANS__OPERATIONS__GO_TO_NEW_VIEW' | translate }}
  </button>
</mat-dialog-actions>

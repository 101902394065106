<ng-container *ngIf="field.items.length >= 10">
  <div class="object-view-show-more">
    <button mat-stroked-button
            *ngIf="!field.$$showAll"
            (click)="field.$$showAll = true"
            translate="TRANS__OBJECT_VIEW_SHOW_MORE__SHOW_ALL">
    </button>
    <button mat-stroked-button
            *ngIf="field.$$showAll"
            (click)="field.$$showAll = false"
            translate="TRANS__OBJECT_VIEW_SHOW_MORE__SHOW_LESS">
    </button>
    <a mat-stroked-button
       *ngIf="listName"
       [routerLink]="'/search/artifact'"
       [queryParams]="{listName: listName}"
       queryParamsHandling="merge"
       translate="TRANS__OBJECT_VIEW_SHOW_MORE__OPEN_CONTENT_LIST"></a>
  </div>
</ng-container>

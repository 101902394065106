import {Component, Input, OnChanges, OnDestroy, WritableSignal} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {SearchContainer} from "../../../core/definitions/search-container";
import {FilterGroupComponent} from "./filter-group/filter-group.component";
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from "@angular/material/expansion";
import {SearchFacetService} from "../../search-facet.service";
import {
  CheckFilterGroup,
  FilterGroupHierarchyNode,
  HierarchicFilterGroup,
  RangeGroup
} from "../../../core/definitions/search-objects";
import {SearchFilterService} from "../../search-filter.service";
import {SearchExecutorService} from "../../search-executor.service";
import {MatButton} from "@angular/material/button";
import {FilterRangeComponent} from "./filter-range/filter-range.component";
import {FocusServiceImplementation} from "../../search-focus.service";
import {FilterHierarchyComponent} from "./filter-hierarchy/filter-hierarchy.component";
import {CategoryMenuItem} from "../filter-menu-category-selector/filter-menu-category-selector.component";
import {NgClass} from "@angular/common";
import {HierarchicFilterGroupService} from "../../hierarchic-filter-group.service";
import {SearchViewFilterService} from "../../search-view-filter.service";

@Component({
  selector: 'app-filter-menu-filter-list',
  standalone: true,
  imports: [
    TranslateModule,
    TranslateModule,
    FilterGroupComponent,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatButton,
    FilterRangeComponent,
    FilterHierarchyComponent,
    NgClass
  ],
  templateUrl: './filter-menu-filter-list.component.html',
  styleUrl: './filter-menu-filter-list.component.scss'
})
export class FilterMenuFilterListComponent implements OnChanges, OnDestroy{
  @Input() fsi: FocusServiceImplementation;
  @Input() loading: boolean;
  @Input() searchContainer: SearchContainer;
  @Input() subCategories: WritableSignal<CategoryMenuItem[]>;

  startExpandedSubCategories: boolean = true;
  staticHierarchicFilterGroup: HierarchicFilterGroup;

  constructor(
    private searchExecutorService: SearchExecutorService,
    private searchFacetService: SearchFacetService,
    private searchFilterService: SearchFilterService,
    private hierarchicFilterGroupService: HierarchicFilterGroupService,
    private searchViewFilterService: SearchViewFilterService
  ) {}

  ngOnChanges() {
    this.searchExecutorService.subscribeToSearchResult(this.searchContainer, () => {
      this.searchViewFilterService.getHierarchicFilterGroupFromSearchView(
        this.searchContainer.currentPathView.search_view).then(hierarchicFilterGroup => {
        this.staticHierarchicFilterGroup = hierarchicFilterGroup;
      })
    });
  }

  ngOnDestroy() {
    this.searchExecutorService.unSubscribeToSearchResult(this.searchContainer, this.initialize);
  }

  initialize() {
    this.searchViewFilterService.getHierarchicFilterGroupFromSearchView(
      this.searchContainer.currentPathView.search_view).then(hierarchicFilterGroup => {
      this.staticHierarchicFilterGroup = hierarchicFilterGroup;
    })
  }

  async clearSetFilters() {
    this.searchFilterService.resetFilter(this.searchContainer);
    if (this.searchContainer.filtersFacets?.hierarchicFilterGroup?.childrenArray?.length > 0) {
      this.checkHierarchicFiltersChecked(this.searchContainer.filtersFacets?.hierarchicFilterGroup?.childrenArray, true);
    }

    await this.searchExecutorService.runSearch(this.searchContainer);
  }

  getCount(filterGroup: CheckFilterGroup) {
    return this.searchFacetService.getTotalCount(filterGroup, this.searchContainer);
  }

  filterGroupToggled(filterGroup: CheckFilterGroup) {
    filterGroup.enabled = true //.hasOwnProperty('enabled') ? !filterGroup.enabled : true;
    filterGroup.active = filterGroup.hasOwnProperty('active') ? filterGroup.active : false;

    if (filterGroup.enabled && !filterGroup.active) {
      this.setSearchSettings().then();
    }
  }

  async hierarchicFilterGroupPreExpanded(expanded: boolean) {
    const hierarchicGroup = this.searchContainer.filtersFacets.hierarchicFilterGroup;
    if (expanded) {
      this.searchContainer.filtersFacets.hierarchicFilterGroup = this.staticHierarchicFilterGroup;
      this.setSearchSettings().then(() => {
        this.hierarchicFilterGroupService.setHierarchicFilterGroup(this.searchContainer);
      });
    } else if (!expanded && hierarchicGroup) {
      this.searchContainer.filtersFacets.hierarchicFilterGroup = null;
      //hierarchicGroup.open = false;
    }
  }

  filterGroupPreExpanded(expanded: boolean, filterGroup: CheckFilterGroup) {
    if (expanded && filterGroup.enabled) {
      setTimeout(() => {
        console.log('filterGroupPreExpanded start: ' + filterGroup.title)
        this.setSearchSettings().then(() => {
          console.log('filterGroupPreExpanded end. ' + filterGroup.title)
        });
      }, 300);
    } else if (!expanded && filterGroup.enabled) {
      filterGroup.enabled = false;
    }
  }

  filterRangeGroupToggled(filterGroup: RangeGroup) {
    filterGroup.enabled = filterGroup.hasOwnProperty('enabled') ? !filterGroup.enabled : true;

    if (filterGroup.enabled) {
      this.searchExecutorService.runSearch(this.searchContainer).then();
    }
  }

  hasFiltersChecked(): boolean {
    if (this.searchContainer.filtersFacets?.filterGroups?.length > 0) {
      for (const filter of this.searchContainer.filtersFacets.filterGroups) {
        if (filter.checkFilters?.length > 0) {
          for (const f of filter.checkFilters) {
            if (f.checked) {
              return true;
            }
          }
        }
      }
    }

    if (this.searchContainer.filtersFacets?.facetRangeGroups?.length > 0) {
      for (const range of this.searchContainer.filtersFacets.facetRangeGroups) {
        if (range.facet_ranges?.length > 0) {
          for (const facet of range.facet_ranges) {
            if (facet.checked) {
              return true;
            }
          }
        }
      }
    }

    if (this.searchContainer.filtersFacets?.hierarchicFilterGroup?.childrenArray?.length > 0) {
      if (this.searchContainer.filtersFacets?.hierarchicFilterGroup?.facet?.selected) {
        return true;
      }

      return this.checkHierarchicFiltersChecked(this.searchContainer.filtersFacets?.hierarchicFilterGroup?.childrenArray);
    }

    return false;
  }

  private checkHierarchicFiltersChecked(filterGroup: FilterGroupHierarchyNode[], deselect: boolean = false): boolean {
    let isChecked = false;

    for (const filter of filterGroup) {
      if (filter.facet?.selected) {
        if (deselect) {
          filter.facet.selected = false;
          filter.open = false;
        }
        isChecked = true;
      }

      if(filter.childrenArray?.length) {
        isChecked = this.checkHierarchicFiltersChecked(filter.childrenArray);
      }
    }

    return isChecked;
  }


  private async setSearchSettings() {
    await this.searchExecutorService.runSearch(this.searchContainer);
    this.searchFilterService.checkMenusFromCheckedFilters(this.searchContainer);
    await this.searchFilterService.setCheckFilterGroups(this.searchContainer, false, true);
  }

  protected readonly Object = Object;
}

import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {SearchContainer} from '../../../core/definitions/search-container';
import {HierarchicFilterGroupService} from "../../hierarchic-filter-group.service";
import {SearchExecutorService} from "../../search-executor.service";
import {SearchViewFilterService} from "../../search-view-filter.service";

@Component({
  selector: 'app-search-filter-hierarchic',
  templateUrl: './search-filter-hierarchic.component.html',
  styleUrls: ['./search-filter-hierarchic.component.scss']
})
export class SearchFilterHierarchicComponent implements OnChanges, OnDestroy {
  @Input() sideMenu;
  @Input() searchContainer: SearchContainer;

  constructor(
    private searchExecutorService: SearchExecutorService,
    private hierarchicFilterGroupService: HierarchicFilterGroupService,
    private searchViewFilterService: SearchViewFilterService) { }

  query = '';

  ngOnChanges(): void {
    this.searchExecutorService.subscribeToSearchResult(this.searchContainer, this.init);
  }

  ngOnDestroy(): void {
    this.searchExecutorService.unSubscribeToSearchResult(this.searchContainer, this.init);
  }

  private init = () => {
    this.searchViewFilterService.getHierarchicFilterGroupFromSearchView(this.searchContainer.currentPathView.search_view).then(
      hierarchicFilterGroup => {
        if (!this.searchContainer.filtersFacets.hierarchicFilterGroup?.childrenArray?.length ||
          hierarchicFilterGroup.title
          !== this.searchContainer.filtersFacets.hierarchicFilterGroup.title) {
          this.hierarchicFilterGroupService.setHierarchicFilterGroup(this.searchContainer).then();
        }
    })
  }
}

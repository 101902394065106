<h2 mat-dialog-title>{{ 'TRANS__ADVANCED_SEARCH__SQL_SEARCH_TITLE' | translate}}</h2>
<mat-dialog-content>
  <mat-label>
    {{ 'TRANS__ADVANCED_SEARCH__SQL_SEARCH_DESCRIPTION' | translate }}
  </mat-label>
  <mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="closeDialog(true)">{{ 'TRANS__ADVANCED_SEARCH__SQL__CONTINUE' | translate }}</button>
    <button mat-button (click)="closeDialog(false)">{{ 'TRANS__ADVANCED_SEARCH__SQL__CANCEL' | translate }}</button>
  </mat-dialog-actions>
</mat-dialog-content>

import {Component, Input, OnInit, signal, WritableSignal} from '@angular/core';
import {AdvFieldQueryGroup, AdvFieldQueryLogicalOperator} from "../../core/definitions/advanced-search-params";
import {SearchContainer} from "../../core/definitions/search-container";
import {AdvancedSearchToolsService} from "../../core/advanced-search-tools.service";
import {MatDialog} from "@angular/material/dialog";
import {SearchExecutorService} from "../search-executor.service";
import { getSearchGroupSetup } from "../../core/definitions/advanced-search/search-groups";
import {
  AdvancedSearchQueryBuilderInformationDialogComponent
} from "./advanced-search-query-builder-information-dialog/advanced-search-query-builder-information-dialog.component";
import {
  AdvancedSearchQueryBuilderSqlDialogComponent
} from "./advanced-search-query-builder-sql-dialog/advanced-search-query-builder-sql-dialog.component";
import {UserCacheService} from "../../core/user-cache.service";

@Component({
  selector: 'app-advanced-search-query-builder',
  templateUrl: './advanced-search-query-builder.component.html',
  styleUrl: './advanced-search-query-builder.component.scss'
})
export class AdvancedSearchQueryBuilderComponent implements OnInit {
  @Input() searchContainer: SearchContainer;

  selectedFieldLogicalOperator: AdvFieldQueryLogicalOperator = null;
  updateFieldSelector: WritableSignal<boolean> = signal(false);
  hasSqlRights = false;
  // Hard-coding this for now, but should come from backend in order to avoid issues if value changes over time
  USER_RIGHTS_ADVANCED_SEARCH_TYPEID = '325a89fe-8816-434a-9578-e1155123f8ed';

  constructor(
    public advancedSearchTools: AdvancedSearchToolsService,
    private searchExecutor: SearchExecutorService,
    private userCache: UserCacheService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.userCache.getUserData().then(userData => {
      if (this.USER_RIGHTS_ADVANCED_SEARCH_TYPEID in userData.rights_ids) {
        this.hasSqlRights = true;
      }
    });
    if (!this.searchContainer.advancedSearchParams) {
      this.advancedSearchTools.initAdvancedSearchParams(this.searchContainer);
      if (this.searchContainer.advancedSearchParams.query_groups.length === 0) {
        this.addRuleGroup();
      }
    }
  }

  addRuleGroup(setup = null) {
    this.advancedSearchTools.addQueryGroup(
      this.searchContainer, this.selectedFieldLogicalOperator, null, null, getSearchGroupSetup(setup)?.setup || null);
  }

  clearAdvancedSearch(runSearch=true) {
    this.advancedSearchTools.clearAdvancedSearchQuery(this.searchContainer);
    if (runSearch) this.searchExecutor.runSearch(this.searchContainer).then();
    this.addRuleGroup();
  }

  removeRuleGroup(groupToRemove: AdvFieldQueryGroup) {
    this.advancedSearchTools.removeFieldQueryGroup(this.searchContainer, groupToRemove);

    if (this.searchContainer.advancedSearchParams.query_groups.length === 0) {
      this.addRuleGroup();
    }
  }

  toggleDatabaseSearch() {
    if (!this.advancedSearchTools.hasNoRulesSet(this.searchContainer)) {
      let dialogRef = this.dialog.open(AdvancedSearchQueryBuilderSqlDialogComponent);
      dialogRef.afterClosed().subscribe(confirmed => {
        if (confirmed) {
          this.searchContainer.advancedSearchParams.db_search = !this.searchContainer.advancedSearchParams.db_search;
          this.toggleFieldUpdate();
        }
      });
    }
  }

  toggleFieldUpdate() {
    this.clearAdvancedSearch(false);
    this.updateFieldSelector.set(!this.updateFieldSelector());
  }

  triggerAdvancedSearch() {
    if (this.advancedSearchTools.hasNoRulesSet(this.searchContainer)) {
      this.dialog.open(AdvancedSearchQueryBuilderInformationDialogComponent);
    }
    else {
      this.searchExecutor.resetSearchPosition(this.searchContainer);
      this.searchExecutor.runSearch(this.searchContainer).then();
    }
  }

  updateAdvancedSearchParams(newGroup: AdvFieldQueryGroup, oldGroup: AdvFieldQueryGroup) {
    this.advancedSearchTools.updateFieldQueryGroup(this.searchContainer, oldGroup, newGroup);
  }

  updateLogicalOperator($event: 'AND' | 'OR', group: AdvFieldQueryGroup) {
    let temp = group;
    group.logical_operator = $event;

    this.advancedSearchTools.updateFieldQueryGroup(this.searchContainer, group, temp);
  }

  protected readonly localStorage = localStorage;
}

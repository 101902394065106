<div class="md-table primus-table-wrapper admin-scroll-layout">
  <table mat-table [dataSource]="dataSource" matSort>
    <caption></caption>
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? toggleSelectionForAllRows() : null"
                      [checked]="selectionState.hasValue() && isAllSelected()"
                      [disabled]="disableSelection"
                      [indeterminate]="selectionState.hasValue() && !isAllSelected()">
        </mat-checkbox>
        <span class="admin-table-select-all-name"
              [ngClass]="{'disabled': disableSelection}">{{ 'TRANS__EDIT_FIELD__CHOOSE' | translate }}</span>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? handleSelectionChanged(row) : null"
                      [disabled]="disableSelection || (rowSelectableFn && !rowSelectableFn(row))"
                      [checked]="selectionState.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    @for (col of columns; track col) {
      <ng-container [matColumnDef]="col.property">
        @if (sortableColumns) {
          <th mat-header-cell *matHeaderCellDef mat-sort-header="">{{ col.title | translate }}</th>
        } @else {
          <th mat-header-cell *matHeaderCellDef>{{ col.title | translate }}</th>
        }
        <td mat-cell
            *matCellDef="let row"
            [ngClass]="{'image-col': col.isImage}"
            (click)="handleRowClicked(row)">

          @if (col.isImage && !!row[col.property]) {
            <app-thumbnail
              [imageIdField]="col.property"
              [item]="row"
              class="primus-table-thumbnail"
              stylingClass="profile-image">
            </app-thumbnail>
          }

          @if (!col.isImage && !isBool(row, col)) {
            <span>{{ row[col.property] }}</span>
          } @else if (!!col.isImage && !row[col.property]) {
            <mat-icon class="none-profile-image">image</mat-icon>
          } @else if (isBool(row, col)) {
            <span>
              @if (!!row[col.property]) {
                <mat-icon>check_box</mat-icon>
              } @else {
                <mat-icon>check_box_outline_blank</mat-icon>
              }
          </span>
          }
      </ng-container>
    }

    <ng-container matColumnDef="rowActions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button [disabled]="rowEditableFn && !rowEditableFn(row)"
                (click)="handleEditClicked(row)">
          @if (editable) {
            <mat-icon>edit</mat-icon>
          } @else {
            <mat-icon color="accent">arrow_forward</mat-icon>
          }
        </button>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
    </tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{'primus-table-row': enableRowSelection}">
    </tr>

  </table>
</div>
<div class="admin-action-toolbar">
  @if (addable !== false) {
    <button mat-stroked-button
            color="primary"
            type="button"
            [disabled]="actionButtonDisabled"
            (click)="addNewRowClicked.emit()">
      <mat-icon matPrefix>add</mat-icon>
      <span
        class="bold uppercase">{{ actionButtonName | translate: {parentName: actionButtonParent?.name || ''} }}</span>
    </button>
  }
  @if (dataSource?.data?.length > 0 || dataSource['numRecords'] > 0) {
    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      [length]="availableItems"
      (page)="pageChanged.emit($event)"
      showFirstLastButtons="true">
    </mat-paginator>
  }
</div>

<div
  class="gallery-item"
  queryParamsHandling="merge"
  [ngClass]="{'gallery-item--selected' : searchContainer.selections.selected[item.artifact_id]}"
  [queryParams]="item.$$refData?.queryParams"
  [routerLink]="getRouterLink(item)"
>
  <div
    class="gallery-item__checkbox-container"
    [ngClass]="{'gallery-item__checkbox-container--selected' : searchContainer.selections.selected[item.artifact_id]}"
  >
    <mat-checkbox
      (click)="select(item, $event)"
      [checked]="searchContainer.selections.selected[item.artifact_id]"
    />
  </div>

  @if(item.hasOwnProperty('$$imageUrl')) {
    <img
      [alt]=""
      class="gallery-item__image"
      [src]="item.$$imageUrl"
    />
  }
  @else {
    <div class="gallery-item__placeholder-container">
      <mat-icon class="gallery-item__placeholder">
        {{ getMaterialSymbol(item.object_type) }}
      </mat-icon>
    </div>
  }
</div>

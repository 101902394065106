import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {CmsApiService} from "../../../core/cms-api.service";
import {Subscription} from "rxjs";
import {filter} from "rxjs/operators";
import {PrimusRouterService} from "../../../core/primus-router.service";

@Component({
  selector: 'reports-index',
  templateUrl: './reports-index.component.html',
  styleUrls: ['./reports-index.component.scss'],
  host: {'class': 'reports-index-page'}
})

export class ReportsIndexComponent implements OnInit, OnDestroy {
  menuItems = [
    { id: 'generatedReports', name: 'TRANS__REPORTS_V2_COMPLETED_HEADER', icon: 'summarize', isOpen: false, showAddButton: true, openButtonLink: '/reports/generated-reports'},
    { id: 'reportDrafts', name: 'TRANS__REPORTS_V2_DRAFTS_HEADER', icon: 'edit', isOpen: false, showAddButton: true, openButtonLink: '/reports/report-drafts'},
    { id: 'reportTemplates', name: 'TRANS__REPORTS_V2_STORED_SELECTIONS', icon: 'auto_awesome_motion', isOpen: false, showAddButton: false, openButtonLink: '/reports/report-templates'}
  ];
  selectedReportId: string;

  private eventsSubscription: Subscription;
  private routerSubscription: Subscription;

  constructor(private readonly cms: CmsApiService,
              private router: Router,
              private route: ActivatedRoute,
              private primusRouter: PrimusRouterService) {

  }

  selectMenuFromCurrentRoute(currentRoute) {
    this.menuItems.forEach((item) => {
      item.isOpen = currentRoute.endsWith(item.id);
    });
  }


  ngOnInit() {
    //IF we load the page at /reports - the main reports page - navigate to the report archive
    if (this.primusRouter.currentState() === 'home.primus.reports') {
      this.primusRouter.navigateState('home.primus.reports.generatedReports');
    }
    this.selectMenuFromCurrentRoute(this.primusRouter.currentState());

    //this.setSelectedReportIdAndOpenMenusOnInit();

    this.routerSubscription = this.primusRouter.navigationHandler(() => {
      //this.selectedReportId = this.primusRouter.paramFromLeafRouterState('report_id')

      //IF we via navigation somehow end up at /reports - the main reports page - navigate to the report archive
      if (this.primusRouter.currentState() === 'home.primus.reports') {
        this.primusRouter.navigateState('home.primus.reports.generatedReports');
      }

      this.selectMenuFromCurrentRoute(this.primusRouter.currentState());
    });
  }

  /**
   * This function sets the selected report id and opens the correct menus when the component is initialized.
   * @private
   */
  private setSelectedReportIdAndOpenMenusOnInit() {
    this.selectedReportId = this.primusRouter.paramFromLeafRouterState('report_id');

    if (this.selectedReportId) {
      this.menuItems.forEach((item) => {
        item.isOpen = false;
      });
    }
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

}

import {Component, Input, OnChanges, Optional} from '@angular/core';
import {OperationService} from '../operation.service';
import {CurrentObjectService} from '../../core/current-object.service';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {OperationDef} from '../../core/definitions/operation-def';
import {style, transition, trigger, useAnimation} from '@angular/animations';
import {fadeIn} from '../../shared/animations';
import {OperationContainer} from '../../core/definitions/operation-container';
import {PrimusRouterService} from '../../core/primus-router.service';
import {Router} from '@angular/router';
import {FeatureFlagsService} from '../../core/feature-flags.service';
import {OperationDialogService} from "../operation-dialog.service";
import {CurrentObjectContext} from "../../object-page-v2/current-object.context";
import {MatDialog} from "@angular/material/dialog";
import {InformationDialogComponent} from "./information-dialog/information-dialog.component";
import {CurrentSearchContext} from "../../object-search/current-search.context";

@Component({
  selector: 'app-operation-menus-object',
  templateUrl: './operation-menus-object.component.html',
  styleUrls: ['./operation-menus-object.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({opacity: 0}),
        useAnimation(fadeIn, {params: {time: '1000ms'}})
      ])
    ])
  ]
})
export class OperationMenusObjectComponent implements OnChanges {

  @Input() operationContainer: OperationContainer;

  constructor(private operationService: OperationService,
              private primusRouter: PrimusRouterService,
              private featureFlagsService: FeatureFlagsService,
              private router: Router,
              private currentObjectService: CurrentObjectService,
              private dialogService: MatDialog,
              @Optional() public currentSearchContext?: CurrentSearchContext
  ) {
  }

  ngOnChanges(): void {

  }

  get isEditing() {
    return this.currentObjectService.isEditing;
  }

  get mediaPage() {
    return this.operationContainer.operationContextObjects[0].meta_type === 'media';
  }

  get rootObject(): SuperObjectModel {
    return this.operationContainer.operationContextObjects[0];
  }

  navigateToNewDesign() {
    setTimeout(() => {
      const currentState = this.primusRouter.currentState();
      if (currentState === 'home.primus.search.artifact') {
        this.router.navigate(['/search', 'artifact', this.currentObjectService.currentObject.artifact_id], {
          queryParamsHandling: 'merge'
        })
      } else {
        this.router.navigate(['/artifact', this.currentObjectService.currentObject.artifact_id], {
          queryParamsHandling: 'merge'
        })
      }
    }, 200)
  }

  navigateToOldDesign() {
    // use a small timeout to allow the slider animation to finish
    setTimeout(() => {
      if (this.currentSearchContext) {
        this.router.navigate(['/search/artifact'], {
          queryParamsHandling: 'merge'
        })
      } else {
        this.router.navigate(['/artifact'], {
          queryParamsHandling: 'merge'
        })
      }
    }, 200)
  }

  openOperation(operation: OperationDef, parentOperation: OperationDef) {
    if (operation.operation_type_id === "75573156-a300-489f-97e6-f0d43def91ed") {
      let dialog = this.dialogService.open(InformationDialogComponent);
      dialog.afterClosed().subscribe((goToNewView: boolean) => {
        if (goToNewView) {
          if (this.featureFlagsService.getFeatureOverride('experimental.useNewObjectPage')) {
            this.featureFlagsService.disableFeatureOverride('experimental.useNewObjectPage');
            this.navigateToOldDesign();
          }
          else {
            this.featureFlagsService.enableFeatureOverride('experimental.useNewObjectPage');
            this.navigateToNewDesign();
          }
        }
      })
    }
    else {
      this.operationService.openOperationView(this.operationContainer, operation, parentOperation).then(res => {
        if (res) {
          console.log('Result: ' + res.status_message);
        }
      });
    }
  }

  toggleOperationMenu(operation: OperationDef) {
    this.operationService.toggleOperationMenu(this.operationContainer, operation).then(res => {
      if (res) {
        console.log('Result: ' + res.status_message);
      }
    });
  }

}

import {Component, Input} from '@angular/core';
import {SearchContainer} from "../../../core/definitions/search-container";
import {SearchObject} from "../../../core/definitions/search-object";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatIcon} from "@angular/material/icon";
import {SearchResultSelectionsService} from "../../search-result-selections.service";
import {NgClass} from "@angular/common";
import {FeatureFlagsService} from "../../../core/feature-flags.service";
import {RouterLink} from "@angular/router";
import {objectTypeToMatSymbol} from "../../../shared/material-icons";

@Component({
  selector: 'app-search-result-view-thumbnail-v2',
  standalone: true,
  imports: [
    MatCheckbox,
    MatIcon,
    NgClass,
    RouterLink
  ],
  templateUrl: './search-result-view-thumbnail-v2.component.html',
  styleUrl: './search-result-view-thumbnail-v2.component.scss'
})
export class SearchResultViewThumbnailV2Component {
  @Input() item: SearchObject;
  @Input() searchContainer: SearchContainer;

  constructor(
    private readonly featureFlagService: FeatureFlagsService,
    private readonly searchResultSelectionService: SearchResultSelectionsService
  ) {}

  getMaterialSymbol(objectType: string) {
    return objectTypeToMatSymbol(objectType);
  }

  getRouterLink(item: SearchObject) {
    if (item.$$refData?.routerLink?.join('/')?.includes('/artifact') && this.objectPageV2) {
      return [...item.$$refData.routerLink, item.artifact_id];
    }
    return item.$$refData?.routerLink
  }

  select(item: SearchObject, event: MouseEvent) {
    event.stopPropagation();
    this.searchResultSelectionService.selectSearchResultItem(item, this.searchContainer, event.shiftKey);
  }

  readonly objectPageV2 = this.featureFlagService.getFeatureFlags().experimental.useNewObjectPage;
}


@if (!generatedReports || generatedReports.status === 'loading') {
  <div class="loading-spinner">{{'TRANS__REPORTS_V2_LOADING_YOUR_REPORTS' | translate}}...</div>
} @else if (generatedReports.status === 'loaded') {
  <div class="report-overview-toolbar" xmlns="http://www.w3.org/1999/html">
    <app-admin-selection-action-menu [selectedItems]="selectedReports">
      <app-admin-selection-action label="TRANS__REPORTS_V2_FETCH_PDFS" [action]="getPdfs"></app-admin-selection-action>
      <app-admin-selection-action label="TRANS__REPORTS_V2_DELETE_REPORTS" [action]="deleteReports"></app-admin-selection-action>
    </app-admin-selection-action-menu>

    <input matInput
           placeholder="{{'TRANS__REPORTS_V2_FILTER_IN_REPORTLIST' | translate}}"
           [(ngModel)]="searchText"
           (ngModelChange)="filterReports()" >
  </div>

  @for(template of filteredReports; track template.id) {
    <div class="report-overview-box">
      <div class="report-preview-image">
        <div class="report-select-checkbox">
          <mat-checkbox
            [checked]="selectedReports.includes(template.id)"
            (change)="reportSelectionChanged($event, template.id)">
          </mat-checkbox>
        </div>
        <img src="{{template.thumbnailUrl}}" class="report-artifact-field-image-img"/>
      </div>
      <div class="report-overview-fields" [routerLink]="['/reports/generated-reports', template.id]">
          <h1>{{template.name}}</h1>
          <label>{{ 'TRANS__REPORTS_V2_DESCRIPTION' | translate }}: <div class="report-overview-field">{{template.shortDescription}}</div></label>
          <label>{{ 'TRANS__REPORTS_V2_TYPE' | translate}}: <div class="report-overview-field">{{'TRANS__REPORTS_V2_CUSTOM_REPORT_TYPE' | translate}}</div></label>
          <label>{{ 'TRANS__REPORTS_V2_REPORT_TEMPLATE' | translate}}: <div class="report-overview-field">
            @if (template.reportViewType) {
              {{template.reportViewType.name}}
            } @else {
              {{'TRANS__REPORTS_V2_NO_REPORT_TEMPLATE_CHOSEN' | translate}}
            }
          </div></label>
          <label>{{ 'TRANS__REPORTS_V2_STATUS' | translate}}: <div class="report-overview-field">
            {{ 'TRANS__REPORTS_V2_PDF_CREATED' | translate}} @if (template.reportDateEnd) { {{'TRANS__REPORTS_V2_ON' | translate}} {{template.reportDateEnd|date:'dd/MM/yyyy kl. HH:mm:ss'}} }
          </div></label>
      </div>
    </div>
  } @empty {
    <div class="report-overview-empty"><p>{{'TRANS__REPORTS_V2_NO_REPORTS_IN_LIST' | translate}}</p></div>
  }
} @else {
  <div class="report-overview-empty"><p>{{'TRANS__REPORTS_V2_NO_REPORTS_IN_LIST' | translate}}</p></div>
}

<router-outlet></router-outlet>
